import { Injectable } from "@angular/core";

import * as constants from "../../constants";

import { BackendProvider, Collection, URLType } from "../../lib/backendProvider";
import { ObservableEventsService } from "../../services/observableEventsService";
import { CacheData, ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService } from "../../services/backend.service";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { RCErrorHandler } from "../../error.handler";

export interface Medicine {
  _id: string;
  name: string;
  units: number;
  dose: string;
  isCream: boolean;
  VTMID: string;
}

@Injectable({
  providedIn: "root",
})
export class MedicineProviderService extends BackendProvider<Medicine> {
  // reading cached data from storage is relatively slow.  as we're going to be needing the same meds over and over again
  // (to re-decorate the same med sessions for the same clients, every time these are refreshed from the backend), we will
  // keep, in-memory, all meds used during this session.  in most cases, each session will therefore require the relatively
  // expensive retrieval of medicines from storage/backend just once (on log in).
  private inMemory: { [id: string]: CacheData<Medicine> } = {};

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<Medicine>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.Medicine,
      Collection.Medicine,
      constants.ONE_WEEK * 4, // keep data in the cache for a maximum of 4 weeks
      constants.ONE_WEEK // but if possible, refresh it after a week
    );
  }

  public async getMedicinesDets(idList: string[]): Promise<Medicine[]> {
    return super.getImmutableList(idList, URLType.Extended, `?f={"_id":{"$in":["%%"]}}`, {
      skipAuth: true,
      inMemory: this.inMemory,
    });
  }
}
