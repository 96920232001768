import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

// tslint:disable-next-line:no-string-literal
if (Symbol["asyncIterator"] === undefined) {
  // tslint:disable-next-line:no-string-literal
  (Symbol as any)["asyncIterator"] = Symbol.for("asyncIterator");
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
  });
