import { Injectable } from "@angular/core";

import { IExpressedClientAllocation, IExpressedEvent } from "./event-provider.service";
import { SingletonProvider } from "../../lib/singletonProvider";
import { IonicStorageService } from "../../services/ionicStorageService";
import { RCErrorHandler } from "../../error.handler";

export interface IInProgressEventLog {
  log?: string;
  wip?: boolean; // it's an interim log
  needsAlert?: boolean;
  editing?: boolean;
}

export interface IPlannedTask {
  task: string;
}

export interface IInProgressTask extends IPlannedTask {
  // The stuff we gather while the carer is recording tasks
  whenCompleted?: Date;
  unableToComplete?: boolean;
}

export interface ITaskLists {
  pending: IInProgressTask[];
  completed: IInProgressTask[];
  unableToComplete: IInProgressTask[];
}

interface IEventUpdatesStore {
  [uniqueEventId: string]: IStoredEventUpdates;
}
interface IStoredEventUpdates {
  [clientId: string]: IStoredClientAllocUpdates;
}
interface IClientAllocUpdates {
  inProgressLog: IInProgressEventLog;
}
interface IStoredClientAllocUpdates extends IClientAllocUpdates {
  inProgressTasks: IInProgressTask[];
}
export interface IWorkingClientAllocUpdates extends IClientAllocUpdates {
  inProgressTasks: ITaskLists;
}

@Injectable({
  providedIn: "root",
})
export class EventUpdatesProvider extends SingletonProvider<IEventUpdatesStore> {
  constructor(ionicStorage: IonicStorageService, errorHandler: RCErrorHandler) {
    super(ionicStorage, errorHandler);
  }

  public get storageKey() {
    return "eventUpdates";
  }

  protected getDefault(): IEventUpdatesStore {
    return {};
  }

  private getStoredUpdates(event: IExpressedEvent, alloc: IExpressedClientAllocation): IStoredClientAllocUpdates {
    let thisEventUpdates = this.data[event.uniqueEventId];
    // if we fail to find any updates for event, it's possible that they were created while it was
    // unmaterialised, but it has since been materialised, and our event cache updated to reflect that.
    // in this case, we can locate the previously-stored working updates using the parentId and recurId
    if (!thisEventUpdates && event.eventId && event.parentId && event.instanceStart) {
      thisEventUpdates = this.data[event.parentId + "-" + event.instanceStart.valueOf()];
    }
    if (!thisEventUpdates) {
      thisEventUpdates = {};
      this.data[event.uniqueEventId] = thisEventUpdates;
    }
    let thisAllocUpdates = thisEventUpdates[alloc.person];
    if (!thisAllocUpdates) {
      thisAllocUpdates = {
        inProgressLog: {
          log: "",
          needsAlert: false,
        },
        inProgressTasks: [],
      };
      thisEventUpdates[alloc.person] = thisAllocUpdates;
    }
    return thisEventUpdates[alloc.person];
  }

  public getWorkingUpdates(event: IExpressedEvent, alloc: IExpressedClientAllocation): IWorkingClientAllocUpdates {
    const stored = this.getStoredUpdates(event, alloc);
    return this.createWorkingUpdates(alloc, stored);
  }

  public saveWorkingTaskUpdates(
    event: IExpressedEvent,
    alloc: IExpressedClientAllocation,
    tasks: ITaskLists
  ): Promise<void> {
    const stored = this.getStoredUpdates(event, alloc);
    stored.inProgressTasks = [...tasks.completed, ...tasks.unableToComplete];
    return this.updateStorage();
  }

  public async saveLogUpdates(
    event: IExpressedEvent,
    alloc: IExpressedClientAllocation,
    log: IInProgressEventLog
  ): Promise<boolean> {
    const stored = this.getStoredUpdates(event, alloc);
    const logChanged = stored.inProgressLog.log !== log.log || stored.inProgressLog.needsAlert !== log.needsAlert;
    if (logChanged) {
      stored.inProgressLog = log;
      await this.updateStorage();
    }
    return logChanged;
  }

  public removeWorkingUpdates(event: IExpressedEvent): Promise<void> {
    delete this.data[event.uniqueEventId];
    return this.updateStorage();
  }

  private createWorkingUpdates(
    clientAlloc: IExpressedClientAllocation,
    storedUpdates: IStoredClientAllocUpdates
  ): IWorkingClientAllocUpdates {
    const taskLists: ITaskLists = {
      pending: [],
      completed: [],
      unableToComplete: [],
    };
    if (clientAlloc.tasks) {
      for (const task of clientAlloc.tasks) {
        const completion = storedUpdates.inProgressTasks.find((c) => c.task === task.task);
        if (!completion) {
          taskLists.pending.push({ task: task.task });
        } else if (completion.whenCompleted) {
          taskLists.completed.push(completion);
        } else if (completion.unableToComplete) {
          taskLists.unableToComplete.push(completion);
        } else {
          taskLists.pending.push(completion);
        }
      }
    }    
    return {
      inProgressLog: storedUpdates.inProgressLog,
      inProgressTasks: taskLists,
    };
  }
}
