import * as jsondiffpatch from "jsondiffpatch";

import { Logger, LogLvl } from "./logger";

export abstract class BaseStorage<T> {
  protected logger: Logger;

  constructor() {
    this.logger = new Logger();
  }

  public abstract getRaw(itemKey: string): Promise<any>;
  public abstract setRaw(raw: any, itemKey: string): Promise<void>;
  public abstract getAllKeys(): Promise<string[]>;
  public abstract removeItem(itemKey: string): Promise<void>;
  public abstract removeAll(): Promise<void>;
  public abstract getItem(itemKey: string): Promise<T>;
  public abstract setItem(item: T, itemKey: string): Promise<void>;

  protected async reviveJSON(strObj: string, itemKey: string): Promise<T> {    
    let obj: T;
    try {
      obj = JSON.parse(strObj, jsondiffpatch.dateReviver);
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.logger.sentryLog(`Invalid cache item: ${strObj}.  Key: ${itemKey}.`, LogLvl.Error);
        this.logger.log(`Cache item ${itemKey} found to be invalid.  Removing...`, LogLvl.Error);
        await this.removeItem(itemKey);
      } else {
        this.logger.sentryLog(`Unexpected error while parsing data from local storage: ${e.message}`, LogLvl.Error);
      }
    }
    return obj;
  }
}

export abstract class BaseObjectStorage<T> extends BaseStorage<T> {
  public async getItem(itemKey: string): Promise<T> {
    let item = await this.getRaw(itemKey);
    // we used to store everything in JSON.  for performance reasons, we now store objects instead.  for the migration, rather
    // than forcing caches to be cleared, we check the type of items as they're read from the cache.  anything that's a string
    // (and shouldn't be), is converted to an object and then immediately written back so that particular item will never need
    // to be converted again.  once this logic has been live for a few weeks, it can be removed.
    if (typeof item === "string" && !["QRCamera", "token"].includes(itemKey)) {
      item = await this.reviveJSON(item, itemKey);
      await this.setItem(item, itemKey);
    }
    return item;
  }
  
  public setItem(item: T, itemKey: string): Promise<void> {
    this.logger.log(`BaseObjectStorage.setItem(): itemKey=${itemKey}`, LogLvl.Light);
    return this.setRaw(item, itemKey);
  }  
}

export abstract class BaseStringStorage<T> extends BaseStorage<T> {
  public async getItem(itemKey: string): Promise<T> {
    const strObj = await this.getRaw(itemKey);
    if (!strObj) {
      return;
    }
    if (typeof strObj === "string") {
      return this.reviveJSON(strObj, itemKey);
    } else {
      // this shouldn't happen, but it has been seen a bit in Sentry.  possibly only during e2e testing?
      return strObj;
    }    
  }

  public setItem(item: T, itemKey: string): Promise<void> {
    this.logger.log(`BaseStringStorage.setItem(): itemKey=${itemKey}`, LogLvl.Light);
    const strObj = JSON.stringify(item);
    if (strObj === "undefined") {
      this.logger.sentryLog(`Invalid attempt to cache "undefined".  Key: ${itemKey}.`, LogLvl.Error);
      return this.removeItem(itemKey);
    } else {
      return this.setRaw(strObj, itemKey);
    }
  }
}
