import { Injectable } from "@angular/core";

import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { Collection, IIdentified, URLType } from "../../lib/backendProvider";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { FullPerson, IPerson } from "../../models/people/fullPerson";
import { IPersonName } from "./event-provider.service";
import { TimestampedImmutableBackendProvider } from "../../lib/immutableBackendProvider";
import { RCErrorHandler } from "../../error.handler";

export interface IPersonStore {
  [personId: string]: IPerson;
}

@Injectable({
  providedIn: "root",
})

/**
 * Provide full personal and contact information for individuals based upon a lookup by id.
 * For a more flexible service providing search capabilities, see also people-provider.service.
 */
export class PersonProviderService extends TimestampedImmutableBackendProvider<IPerson> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<FullPerson>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.PersonLookup,
      Collection.Person
    );
  }

  public async getRawPersonDets(personId: string): Promise<IPerson> {
    // The first time any data is requested from the backend, the server will also check that we have the
    // correct time zone on the phone.  The obvious alternative would be to use the timezone from the
    // organisation to create a time on the device, but then we would need moment timezone on the device,
    // which is expensive
    const result = await super.internalGetRecord(personId, URLType.Extended, `personForMobile/%%`);
    if (!result) {
      return; // it is possible to get nothing when the cache is empty and the network request fails
    }
    return result.data;
  }

  private rawObjToFullPerson(person: IPerson): FullPerson {
    return new FullPerson(person._id, person);
  }

  public async getRecord(personId: string): Promise<FullPerson> {
    const raw = await this.getRawPersonDets(personId);
    if (raw) {
      return this.rawObjToFullPerson(raw);
    }
  }

  public async getPersonName(personId: string): Promise<IPersonName & IIdentified> {
    const raw = await this.getRawPersonDets(personId);
    if (raw) {
      return { _id: raw._id, title: raw.title, givenName: raw.givenName, familyName: raw.familyName };
    }
  }

  public async getCachedPersonDets(personId: string, throwOnCacheMiss = true): Promise<FullPerson> {
    const raw = await this.getCachedRecord(personId, throwOnCacheMiss);
    if (raw) {
      return this.rawObjToFullPerson(raw);
    }
  }

  public async getPersonSex(personId: string): Promise<string> {
    // Get a client's sex to ensure that male customers don't get the Female bodymap when
    // an unauthenticated user looks at their record.
    const person = await super.getImmutable(personId, URLType.Extended, `getNonPersonalDataForMobile/%%`);
    // then delete the non personal data from the cache in case the carer logs in
    await this.readCache.remove(this.getCacheKey(personId));
    return person.data.sex;
  }

  private setCareplanViewed(serviceUser: IPerson, workerId: string, awareOfCareplansUpTo: Date) {
    if (!serviceUser.client.workerCareplanAwareness) {
      serviceUser.client.workerCareplanAwareness = [];
    }
    const rec = serviceUser.client.workerCareplanAwareness.find((wca) => wca.workerId === workerId);
    if (rec) {
      rec.awareOfCareplansUpTo = awareOfCareplansUpTo;
    } else {
      serviceUser.client.workerCareplanAwareness.push({ awareOfCareplansUpTo, workerId });
    }
  }

  public async logCareplanViewedAtBackend(serviceUser: FullPerson, workerId: string, awareOfCareplansUpTo: Date) {
    // record the awareOfCareplansUpTo date against serviceUser so that any pages using this object
    // (having previously requested it from our cache) will see that update immediately
    this.setCareplanViewed(serviceUser, workerId, awareOfCareplansUpTo);

    // update our cache (and in-memory store, if we have one) so anyone who requests this service user from
    // us before it is next refreshed from the backend will see the awareOfCareplansUpTo date
    const cachedClient = await this.getCachedImmutable(serviceUser._id);
    this.setCareplanViewed(cachedClient, workerId, awareOfCareplansUpTo);
    await this.store(cachedClient, serviceUser._id);
    const inMemoryPerson = this.getFromInMemoryStore(serviceUser._id);
    if (inMemoryPerson) {
      this.setCareplanViewed(inMemoryPerson, workerId, awareOfCareplansUpTo);
    }

    // queue an update to the backend
    const idStr = `logCareplanViewed/${serviceUser._id}/${workerId}/${awareOfCareplansUpTo.valueOf()}`;
    await super.cacheABackendWrite(URLType.Extended, idStr, {});
  }
}
