<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="main-menu">
          <ion-list-header><ion-label>Menu</ion-label></ion-list-header>
          <ion-note [hidden]="!auth.userInfo">{{ auth?.userInfo?.givenName}} {{ auth?.userInfo?.familyName}}</ion-note>
          <ion-menu-toggle auto-hide="false">
            <ion-item  *ngFor="let p of menuOptions()" [id]=p.id [routerDirection]="p.direction" [routerLink]="[p.url || p.urlFunc(auth)]">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>{{p.title}}</ion-label>
            </ion-item>
            <ion-item id="log-out-opt" [hidden]="!loggedIn" (click)="gotoLoginPage('')">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>Log Out</ion-label>
            </ion-item>
            <ion-item id="clearCache" [hidden]="!cacheIsClearable" (click)="clearCacheAndLogout(true)">
              <ion-icon slot="start" name="trash"></ion-icon>
              <ion-label>Clear Cache <span *ngIf="loggedIn"> and Log Out</span></ion-label>
            </ion-item>
            <ion-item id="showCache" [hidden]="!isDevMode()" (click)="showCache()">
              <ion-icon slot="start" name="eye-outline"></ion-icon>
              <ion-label>Show Write Cache</ion-label>
            </ion-item>
            <ion-list-header color="light" *ngIf="localMenuOpts.length > 0"><ion-label>{{ localMenuDesc }}</ion-label></ion-list-header>
            <ion-item *ngFor="let o of localMenuOpts; let i = index" (click)="handleLocalOption(o)">
              <ion-icon slot="start" [name]="o.icon"></ion-icon>
              <ion-label>{{o.title}}</ion-label>
            </ion-item>
            <ion-item color="light"><ion-label>V {{ RELEASE }}</ion-label></ion-item>
            <ion-item color="light"><ion-label><a href="http://www.reallycare.org/privacy.html">Privacy</a></ion-label></ion-item>
            <ion-item *ngIf="!!beta" color="light" (click)="sendStatus()"><ion-label>Send Status</ion-label></ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
