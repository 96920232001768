import { Injectable } from "@angular/core";

import { Collection, IIdentified } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { ITimestamped, TimestampedImmutableBackendProvider } from "../../lib/immutableBackendProvider";
import { RCErrorHandler } from "../../error.handler";

// this is a cut-down version of plait.IEventType containing only those properties that we require
export interface IEventType extends IIdentified, ITimestamped {
  name: string;
  hasCarer: boolean;
  hasClient: boolean;
  allocatedColour: string;
  allocatedFontColour: string;
  unallocatedColour: string;
  unallocatedFontColour: string;
}

@Injectable({
  providedIn: "root",
})
export class EventTypeProviderService extends TimestampedImmutableBackendProvider<IEventType> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IEventType>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.EventType,
      Collection.EventType
    );
  }
}
