import { Injectable } from "@angular/core";

import { ONE_HOUR, ONE_SECOND } from "../constants";
import { LogLvl } from "../lib/logger";
import { BaseProviderStorage } from "../lib/baseProviderStorage";
import { SingletonProvider } from "../lib/singletonProvider";
import { IonicStorageService } from "./ionicStorageService";
import { RCErrorHandler } from "../error.handler";

export interface CacheData<T> {
  // The data that is being cached
  obj: T;
  // When was the data last retrieved from the backend?
  lastRead: number;
  // When was the data last accessed?
  lastUsed: number;
}

export interface KeyedCacheData<T> extends CacheData<T> {
  key: string;
}

export interface DataFromCache<T> extends CacheData<T> {
  needsRefresh?: boolean;
}

export const defaultCacheRefreshAfter = ONE_SECOND;
export const defaultCacheAgeLimit = ONE_HOUR * 12;

@Injectable({
  providedIn: "root",
})
export class ReadCacheService<T> extends BaseProviderStorage<CacheData<T>> {
  constructor(ionicStorage: IonicStorageService, errorHandler: RCErrorHandler) {
    super(ionicStorage, errorHandler);
  }

  public async clear(singletonCaches: SingletonProvider<any>[], preserveSingletonData: boolean): Promise<void> {
    await this.storage.removeAll();
    for (const cache of singletonCaches) {
      if (preserveSingletonData) {
        // write the singleton data back to the (now empty) storage
        await cache.updateStorage();
      } else {
        // replace cache.data with cache's default (blank) version of it, so we lose the in-memory data as well as what's in storage
        await cache.retrieveData();
      }
    }
  }

  /**
   * Return the item from the cache with the given key, unless it has expired,
   * in which case null will be returned
   */
  public async get(
    cacheKey: string,
    ageLimit: number = defaultCacheAgeLimit, // 0 means don't check for aging
    refreshAfter: number = defaultCacheRefreshAfter // 0 means never remove the item
  ): Promise<DataFromCache<T>> {
    this.logger.log(`Searching cache for ${cacheKey}`, LogLvl.Heavy);
    const now = new Date();
    let cached: DataFromCache<T> = await this.storage.getItem(cacheKey);
    if (cached) {
      const time: number = now.getTime();
      cached.needsRefresh = refreshAfter > 0 && cached.lastRead < time - refreshAfter;
      const foundMsg =
        `Found it!\n` +
        `Last Read: ${new Date(cached.lastRead)}\n` +
        `Refresh After: ${new Date(time - refreshAfter)}\n` +
        `NeedsRefresh: ${cached.needsRefresh}`;
      this.logger.log(foundMsg, LogLvl.Heavy);
      if (ageLimit === 0 || cached.lastRead > time - ageLimit) {
        cached.lastUsed = time;
        await this.storage.setItem(cached, cacheKey);
      } else {
        this.logger.log(`Cache item ${cacheKey} found to be stale.  Removing...`, LogLvl.Heavy);
        cached = null;
        await this.storage.removeItem(cacheKey);
      }
    }
    if (cached) {
      this.logger.log(`Cache.get returning ${JSON.stringify(cached, null, 2)}`, LogLvl.Insane);
    }
    return cached;
  }

  /**
   * Return the item from the cache with the given key, regardless of expiry
   */
  public async getNoExpiry(cacheKey: string): Promise<DataFromCache<T>> {
    this.logger.log(`Searching cache for ${cacheKey} (no expiry concern)`, LogLvl.Heavy);
    const cached = await this.storage.getItem(cacheKey);
    if (cached) {
      this.logger.log(`Cache.getNoExpiry returning ${JSON.stringify(cached, null, 2)}`, LogLvl.Insane);
    }
    return cached;
  }

  /**
   * Return all of the items from the cache whose key starts with the given prefix.
   * To enable them to be written back later, the items are decorated with their respective cache keys.
   */
  public async getAll(cacheKeyPrefix: string): Promise<KeyedCacheData<T>[]> {
    const results: KeyedCacheData<T>[] = [];
    const allKeys = await this.storage.getAllKeys();
    for (const key of allKeys) {
      if (key.startsWith(cacheKeyPrefix)) {
        const cached = await this.storage.getItem(key);
        if (cached) {
          results.push(Object.assign({ key }, cached));
        }
      }
    }
    return results;
  }

  public async getAllCacheKeys(prefix?: string): Promise<string[]> {
    let allKeys = await this.storage.getAllKeys();
    if (prefix) {
      allKeys = allKeys.filter((k) => k.startsWith(prefix));
    }
    return allKeys;
  }

  public async remove(cacheKey: string): Promise<void> {
    this.logger.log(`Removing ${cacheKey} from cache`, LogLvl.Light);
    await this.storage.removeItem(cacheKey);
  }

  public async store(obj: T, cacheKey: string): Promise<CacheData<T>> {
    const now = new Date().getTime();
    const cacheItem: CacheData<T> = {
      obj,
      lastRead: now,
      lastUsed: now,
    };
    await this.storage.setItem(cacheItem, cacheKey);
    return cacheItem;
  }

  public async storeLocalChange(obj: T, cacheKey: string, lastRead: number): Promise<CacheData<T>> {
    const now = new Date().getTime();
    const cacheItem: CacheData<T> = {
      obj,
      lastRead,
      lastUsed: now,
    };
    await this.storage.setItem(cacheItem, cacheKey);
    return cacheItem;
  }
}
