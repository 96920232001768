import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";

import { RCErrorHandler, UnexpectedLocalError } from "../error.handler";
import { UtilsService } from "./utilsService";
import { LogLvl, Logger } from "../lib/logger";

@Injectable({
  providedIn: "root",
})
export class IonicStorageService {
  private storagePromise: Promise<Storage>;
  private logger: Logger;

  constructor(private ionic: Storage, private errorHandler: RCErrorHandler, private utils: UtilsService) {
    this.connectToIonic();
    this.logger = new Logger();
  }

  private connectToIonic() {
    this.storagePromise = this.ionic.create()
  }

  // A number of errors have been observed in Sentry which - according to various web posts - occur on iOS when the app is going
  // into, or coming out of, a dormant (sleeping) state, and it makes a storage request at an inopportune time.
  // as these errors occur quite frequently, we will attempt to handle them by re-trying up to three times, with a delay between
  // the first and second attempt of 1.5 seconds, and between the second and third attempt, of 3 seconds.  if ANY other error
  // is encountered during this process, it will be immediately rethrown.  if - after the third attempt - we've still failed
  // to complete the requested operation, we will re-throw the final error if rethrowOnThirdFailure is true, or otherwise, return nothing.
  private async rawOpWithRetry<T>(
    op: (storage: Storage) => Promise<T>,
    opDesc: string,
    rethrowOnThirdFailure: boolean,
    sentryClues: any
  ): Promise<T> {
    const attempts: string[] = [];
    function isKnownIssue(attemptNumber: number, e: Error, handler: RCErrorHandler): boolean {
      const extracted = handler.extractError(e);
      attempts.push(`attempt ${attemptNumber}: ${extracted.message}`);
      return [
        "Attempt to get a record from database without an in-progress transaction",
        "Attempt to delete range from database without an in-progress transaction",
        "Attempt to iterate a cursor that doesn't exist",
        "Connection to Indexed Database server lost. Refresh the page to try again",
        "The transaction was aborted, so the request cannot be fulfilled",
        "Transaction timed out due to inactivity",
      ].some((known) => extracted.message.includes(known));
    }
    function rethrow(e: Error, handler: RCErrorHandler, context: string) {
      const extracted = handler.extractError(e);
      throw new UnexpectedLocalError(`${opDesc} failed ${context} with message: ${extracted.message}`, sentryClues);
    }
    let result: T;
    let storage: Storage;
    try {
      this.logger.log(`First attempt at ${opDesc}...`, LogLvl.Light);
      storage = await this.storagePromise;
      result = await op(storage);
      return result;
    } catch (e1) {
      // anything we don't recognise as a potentially-recoverable error condition gets immediately rethrown
      if (!isKnownIssue(1, e1, this.errorHandler)) {
        rethrow(e1, this.errorHandler, "on 1st attempt");
      }
      // otherwise, we wait 1.5 seconds, before giving the operation another go...
      await this.utils.delay(1500);
      try {
        this.logger.log(`Second attempt at ${opDesc}...`, LogLvl.Light);
        // we'll recreate the storage promise before making our second attempt, as "reconnecting" to Ionic
        // storage seems like a good idea if our previous connection encountered an error.
        this.connectToIonic();
        storage = await this.storagePromise;
        result = await op(storage);
        return result;
      } catch (e2) {
        if (!isKnownIssue(2, e2, this.errorHandler)) {
          rethrow(e2, this.errorHandler, "on 2nd attempt");
        }
        // after waiting a further 3 seconds, we'll give it one more try...
        await this.utils.delay(3000);
        try {
          this.logger.log(`Third attempt at ${opDesc}...`, LogLvl.Light);
          this.connectToIonic();
          storage = await this.storagePromise;
          result = await op(storage);
          return result;
        } catch (e3) {
          if (!isKnownIssue(3, e3, this.errorHandler)) {
            rethrow(e3, this.errorHandler, "on 3rd attempt");
          }
          // after three successive "known" issues, we give up!  log the attempts to the console so these can be seen in
          // the Sentry breadcrumbs
          this.logger.log(attempts.join("; "), LogLvl.Error);
          // then either we rethrow the 3rd error (this will result in the user being forcibly logged-out - necessary in
          // the case of a cache write when later code will be expecting the cache item to have been successfully written),
          // or we log it to Sentry and then return nothing (suitable for a cache read - when a backend request will
          // hopefully be made in response)
          if (rethrowOnThirdFailure) {
            rethrow(e3, this.errorHandler, "3 times");
          } else {
            this.logger.sentryLog(`${opDesc} failed 3 times`, LogLvl.Error);
            return;
          }
        }
      }
    }
  }

  public async setRaw<T>(raw: T, itemKey: string): Promise<void> {
    return this.rawOpWithRetry<void>(((storage: Storage) => storage.set(itemKey, raw)).bind(this), "setRaw", true, {
      itemKey,
    });
  }

  public async getRaw<T>(itemKey: string): Promise<T> {
    return this.rawOpWithRetry<T>(((storage: Storage) => storage.get(itemKey)).bind(this), "getRaw", false, {
      itemKey,
    });
  }

  public async removeItem(itemKey: string): Promise<void> {
    return this.rawOpWithRetry<void>(((storage: Storage) => storage.remove(itemKey)).bind(this), "removeItem", false, {
      itemKey,
    });
  }

  public async getAllKeys(): Promise<string[]> {
    return this.rawOpWithRetry<string[]>(((storage: Storage) => storage.keys()).bind(this), "getAllKeys", true, {});
  }

  public async removeAll(): Promise<void> {
    return this.rawOpWithRetry<void>(((storage: Storage) => storage.clear()).bind(this), "removeAll", true, {});
  }
}
