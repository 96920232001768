import { Injectable } from "@angular/core";

import { BackendProvider, Collection, DataSource, URLType } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { ONE_HOUR, ONE_MINUTE } from "../../constants";
import { LogLvl } from "../../lib/logger";
import { RCErrorHandler } from "../../error.handler";

export interface TagOwner {
  _id: string;
  client?: { isClient: boolean };
  careWorker?: { isCareWorker: boolean };
  sysUserData?: { roles: string[] };
  // tagID: string;
}

@Injectable({
  providedIn: "root",
})

/**
 * Manage the ownership of tags.
 * Though in the backend, tag ids are stored against the Person who owns that particular
 * tag, here we store the owner (a Person) against a key of their tag id.
 */
export class TagOwnerProviderService extends BackendProvider<TagOwner> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<TagOwner>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.TagOwner,
      Collection.Person,
      ONE_HOUR * 12, // Keep data in the cache for a maximum of 12 hours
      ONE_MINUTE // But, where possible, refresh it after one minute
    );
  }

  public getFromTag(tagId: string): AsyncIterableIterator<{ data: TagOwner; source: DataSource }> {
    this.logger.log(`getting tag ${tagId} from provider`, LogLvl.Heavy);
    return super.getChangeableId(tagId, URLType.Extended, "tag/%%");
  }

  public update(id: string, data: any): Promise<void> {
    // TODO: Update the cache
    return super.cacheABackendWrite(URLType.Standard, id, data);
  }

  public assignAssets(data: any, tagId?: string): Promise<void> {
    // assign the phone
    // assign the tag - unless it is for QRCdoe
    // TODO: Update the cache
    const postObj: any = tagId ? { tagId } : {};
    if (data.careWorker && data.careWorker.isCareWorker && data.deviceUUID) {
      postObj.deviceUUID = data.deviceUUID;
    }
    return super.cacheABackendWrite(URLType.Extended, data._id, postObj);
  }
}
