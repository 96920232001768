/* eslint-disable no-console */
import { ChangeDetectorRef, Component, NgZone, OnDestroy } from "@angular/core";
import { AlertController, LoadingController, MenuController, Platform } from "@ionic/angular";
import { ScanResult } from "@capacitor-community/barcode-scanner";
import { SwUpdate } from "@angular/service-worker";
import { Router } from "@angular/router";
import { captureMessage } from "@sentry/browser";

import { ONE_DAY, ONE_MINUTE } from "./constants";

import { AuthService } from "./providers/data/auth.service";
import { ReadCacheService } from "./services/readCacheService";
import { NFCService } from "./providers/nfc/nfc.service";
import { ObservableEventsService } from "./services/observableEventsService";
import { RELEASE } from "./services/release";
import { BETA, environment } from "../environments/environment";
import { CarerEventProviderService, IExpressedCarerEvent } from "./providers/data/carer-event-provider.service";
import { UserRole } from "./models/people/user";
import { WriteCacheService } from "./services/writeCacheService";
import { EventUpdatesProvider } from "./providers/data/event-updates-provider.service";
import { ReleaseProvider } from "./providers/data/release-provider.service";
import { Logger, LogLvl } from "./lib/logger";
import { HistoricEventLogProviderService } from "./providers/data/historic-event-logs-provider.service";
import { CurrentClientProviderService } from "./providers/data/current-client-provider.service";
import { IonicStorageService } from "./services/ionicStorageService";
import { RCErrorHandler } from "./error.handler";
import { SingletonProvider } from "./lib/singletonProvider";
import { BaseEventProviderService, ICarerAllocation, IExpressedEvent, isAdHoc } from "./providers/data/event-provider.service";
import { BackendService, NetworkStatus } from "./services/backend.service";

export interface MPlaitLocalMenuOption {
  title: string;
  instance: any;
  funcName: string;
  icon?: string;
  params?: Array<any>;
}

export interface MPlaitLocalMenuObject {
  opts: Array<MPlaitLocalMenuOption>;
  desc: string;
}

interface MPlaitPage {
  title: string;
  url?: string;
  icon?: string;
  direction?: string;
  requiresNFC?: boolean;
  auth?: boolean;
  requiresAdmin?: boolean;
  visibleFunc?: () => boolean;
  urlFunc?: (auth: AuthService) => string;
  id?: string;
}

interface IScanMatch {
  evt: IExpressedEvent;
  type: string;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class PlaitMobileComponent implements OnDestroy {
  public pages: MPlaitPage[] = [
    {
      title: "Home",
      icon: "home",
      direction: "root",
      auth: true,
      id: "menu-home",
      urlFunc: this.getHomePageUrl,
    },
    {
      url: "/find-client",
      icon: "search",
      direction: "root",
      title: "Find Client",
      auth: true,
      visibleFunc: this.showFindClient,
    },
    {
      url: "/assign-phone",
      title: "Assign Phone",
      direction: "root",
      icon: "phone-portrait",
      auth: true,
      requiresAdmin: true,
      visibleFunc: this.showAssignPhone,
    },
    {
      url: "/request-bookings-list",
      title: "Bookings Listing",
      direction: "root",
      icon: "reader-outline",
      auth: true,
      visibleFunc: this.showBookingsListing,
    },
  ];
  RELEASE = RELEASE;
  localMenuOpts: Array<MPlaitLocalMenuOption> = [];
  public localMenuDesc: string;
  private logger: Logger;
  private debug: boolean;
  public cacheIsClearable: boolean;
  public beta;
  public loggedIn: boolean;

  constructor(
    events: ObservableEventsService,
    public platform: Platform,
    private cd: ChangeDetectorRef,
    private menu: MenuController,
    public auth: AuthService,
    private nfcHandler: NFCService,
    private router: Router,
    private alertCtrl: AlertController,
    private carerEventProvider: CarerEventProviderService,
    private readCache: ReadCacheService<any>,
    private writeCache: WriteCacheService,
    private eventUpdatesCache: EventUpdatesProvider,
    private releaseCache: ReleaseProvider,
    private historicEventLogProvider: HistoricEventLogProviderService,
    private currentClientProvider: CurrentClientProviderService,
    private ionicStorage: IonicStorageService,
    private swUpdate: SwUpdate,
    private errorHandler: RCErrorHandler,
    private loadingCtrl: LoadingController,
    private zone: NgZone,
    private backendService: BackendService
  ) {
    this.beta = BETA;
    events.subscribe("auth:error", () => {
      this.logger.log("forced logout requested: error case", LogLvl.Light);
      this.gotoLoginPage("?error=1"); // error parameter doesn't actually do anything, but might help with debugging
    });
    events.subscribe("auth:reload", () => {
      this.logger.log("forced reload requested", LogLvl.Light);
      window.location.reload();
    });
    events.subscribe("auth:unauthorised", () => {
      this.logger.log("forced logout requested: unauthorised case", LogLvl.Light);
      this.gotoLoginPage("?unauthorised=1");
    });
    events.subscribe("auth:noData", (reason?: { message: string }) => {
      this.logger.log("auth:noData", LogLvl.Light);
      this.handleNoData(reason?.message);
    });
    events.subscribe("auth:authenticated", (redirect?: { skipHomePageRedirect: boolean }) => {
      this.loggedIn = true;
      // We don't want to go to the homepage if we are already on the client meds page, or if we have been explictly
      // asked not to (as would be the case when a page reload takes place - causing a brand new
      // auth.service to be introduced - and everything is being initialised in the context of an existing "session")
      if (!redirect?.skipHomePageRedirect && this.router.url.slice(0, 13) !== "/client-meds/") {
        this.gotoHomepage().catch((e) =>
          this.errorHandler.handleUnexpected(e, ["PlaitMobileComponent", "auth:authenticated", "gotoHomepage"])
        );
      }
    });
    events.subscribe("scan:client", (data: ScanResult) => {
      this.handleClientScan(data);
    });
    events.subscribe("tag:client", (data) => {
      this.handleClientTagTouch(data);
    });
    events.subscribe("tag:noReadFor", () => {
      this.nfcHandler.readTagFor = null;
    });
    events.subscribe("menu:setLocal", (data) => {
      this.localMenuDesc = data.desc;
      this.localMenuOpts = data.opts;
      this.cd.detectChanges();
    });
    events.subscribe("tag:assign", (data: any) => {
      if (Array.isArray(data)) {
        data = data[0];
      }
      this.nfcHandler.readTagFor = data;
    });
    events.subscribe("displayError", (data: any) => {
      let body: string;
      let title: string;
      if (typeof data === "string") {
        body = data;
      } else if (typeof data === "object") {
        ({ body, title } = data);
      }
      this.displayError(body, title).catch((e) => {
        this.errorHandler.handleUnexpected(e, ["PlaitMobileComponent", "displayError subscription"]);
      });
    });
    events.subscribe("updateCacheStatus", () => {
      this.cacheClearable();
    });
    events.subscribe("forciblyClearCache", (clockFraud=false) => {
      if (this.isDevMode() || clockFraud) {
        this.clearCacheAndLogout(false);
      }
    });
    this.logger = new Logger();
    this.initializeApp().catch((e) => this.errorHandler.handleUnexpected(e, ["PlaitMobileComponent", "initializeApp"]));
  }

  public isDevMode(): boolean {
    return environment.enableDebug && !environment.production;
  }

  ngOnDestroy() {
    if (this.ionicStorage) {
      this.ionicStorage
        .removeItem("token")
        .catch((e) =>
          this.errorHandler.handleUnexpected(e, [
            "PlaitMobileComponent",
            "ngOnDestroy",
            "ionicStorageService.removeItem",
          ])
        );
    }
  }

  public logout() {
    this.loggedIn = false;
    return this.router.navigate(["/"]);
  }

  public async initializeApp() {
    this.debug = environment.enableDebug;
    await this.platform.ready();
    // retrieve all of the singleton data from storage
    const singletonCaches = this.getSingletonCaches();
    for (const cache of singletonCaches) {
      await cache.retrieveData();
    }
    const device = (window as any).device;
    const uuid: string = device?.uuid || "None";
    this.auth.setDeviceUUID(uuid);
    this.cacheClearable();
    const currentRelease = this.releaseCache.getCurrentRelease();
    this.logger.log(`Current release: ${RELEASE}; Previous release: ${currentRelease.sha}`, LogLvl.Light);
    if (currentRelease.sha !== RELEASE) {
      // if we set this now, the value will be written to storage when we call clearCacheAsync() in just a moment...
      currentRelease.sha = RELEASE;
      this.logger.log("New release detected!  Creating spinner...", LogLvl.Light);
      const spinner = await this.loadingCtrl.create({
        message: "<p>New release detected.</p><p>Please wait while we get things ready.</p>",
      });
      this.logger.log("Presenting spinner...", LogLvl.Light);
      await spinner.present();
      this.logger.log("Clearing all (non-singleton) caches...", LogLvl.Light);
      try {
        await this.clearCacheAsync(true);
      } catch (e) {
        const message = `Failed to clear caches.  The error was: ${e.message}`;
        this.logger.log(message, LogLvl.Error);
        this.logger.sentryLog(message, LogLvl.Error);
      }
      this.logger.log("Attempting to dismiss spinner...", LogLvl.Light);
      await spinner.dismiss();
      this.logger.log("Spinner successfully dismissed.", LogLvl.Light);
    }
    this.swUpdate?.versionUpdates?.subscribe(async (evt) => {
      switch (evt.type) {
        case "VERSION_DETECTED":
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case "VERSION_READY": {
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          const message =
            "A new version has been downloaded.  Would you like to start using it now (note that you will have to log in again)?";
          const errorDialog = await this.alertCtrl.create({
            header: "New Version Downloaded!",
            message,
            buttons: [
              {
                text: "Yes",
                handler: () => {
                  this.clearCacheAndLogout(true);
                },
              },
              {
                text: "No",
                handler: async () => {
                  await errorDialog.dismiss();
                },
              },
            ],
          });
          await errorDialog.present();
          break;
        }
        case "VERSION_INSTALLATION_FAILED":
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          captureMessage(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  public menuOptions(): MPlaitPage[] {
    const isAdmin: boolean = this.auth.isAdmin();
    return this.pages.filter((p) => {
      return (
        (this.loggedIn || !p.auth) &&
        (isAdmin || !p.requiresAdmin) &&
        (this.nfcHandler.haveNFC() || !p.requiresNFC) &&
        (!p.visibleFunc || p.visibleFunc.bind(this)())
      );
    });
  }

  public haveNFC(): boolean {
    return this.nfcHandler.haveNFC();
  }

  // public storage(): number {
  //   let localStorage = window.localStorage;
  //   let size = 0;
  //   for(var i=0, len=localStorage.length; i<len; i++) {
  //     var key = localStorage.key(i);
  //     var value = localStorage[key];
  //     size += key.length + value.length;
  //   }
  //   return size;
  // }

  public showAssignPhone(): boolean {
    return this.nfcHandler.haveNFC() || this.auth.userInfo?.orgObj?.allowQRCodeLoginOnMobile?.includes("Allow");
  }

  public showFindClient(): boolean {
    return this.auth.isAdmin() || this.auth.allowFindClient();
  }

  private navigateByUrl(url: string): Promise<boolean> {
    // always log this before navigating, because if an NG04002 noMatchError is thrown, we often won't see the
    // url in Sentry, so won't be able to see which invalid route was requested
    this.logger.log(`navigateByUrl called by app.component: navigating to ${url}`, LogLvl.Light);
    // when navigation is performed within a callback (such as the subscriptions used extensively in this module),
    // we need to wrap it in this.zone.run to avoid "Navigation triggered outside Angular zone, did you forget to call 'ngZone.run()'?"
    // doing this even when we're not in the context of a callback is harmless, so we'll do this every time:
    return this.zone.run(() => {
      return this.router.navigateByUrl(url);
    });
  }

  private gotoHomepage(): Promise<boolean> {
    if (!this.auth.userInfo) {
      throw new Error("Unauthorized home page access");
    }
    return this.navigateByUrl(this.getHomePageUrl(this.auth));
  }

  private showBookingsListing() {
    return this.auth.userInfo?.role === UserRole.CareWorker && this.auth.schedulingMode();
  }

  private getHomePageUrl(auth: AuthService): string {
    const user = auth?.userInfo;
    if (!user?.orgObj) {
      return;
    }
    if (auth.schedulingMode()) {
      if (user.role === UserRole.CareWorker) {
        return "/home-scheduling-carer";
      } else if (user.clientOffset !== undefined) {
        if (user.clientsHasAccessTo[user.clientOffset]) {
          return `/home-scheduling-client/${user.clientsHasAccessTo[user.clientOffset]._id}`;
        } else {
          return;
        }
      } else {
        return "/select-client";
      }
    } else {
      if (user.role === UserRole.CareWorker) {
        return "/home-non-scheduling";
      } else if (user.clientOffset !== undefined) {
        if (user.clientsHasAccessTo[user.clientOffset]) {
          return `/client-meds/${user.clientsHasAccessTo[user.clientOffset]._id}`;
        } else {
          return;
        }
      } else {
        return "/select-client";
      }
    }
  }

  public gotoLoginPage(param: string) {
    this.loggedIn = false;
    this.localMenuDesc = undefined;
    this.localMenuOpts = [];
    this.navigateByUrl("/login" + param).catch((e) =>
      this.errorHandler.handleUnexpected(e, ["gotoLoginPage", "navigateByUrl"])
    );
  }

  private handleNoData(message: string): void {
    let param = "?noData=1";
    if (message) {
      message = encodeURIComponent(`The error was: ${message}`);
      param += `&message=${message}`;
    }
    this.gotoLoginPage(param);
  }

  private async displayError(message: string, header = "Error!", cssClass?: string) {
    const errorDialog = await this.alertCtrl.create({
      header,
      message,
      cssClass,
      buttons: [
        {
          text: "OK",
          handler: async () => {
            await errorDialog.dismiss();
            return false;
          },
        },
      ],
    });
    return errorDialog.present();
  }

  private findCarerOnEvent(aCarerId: string, event: IExpressedCarerEvent): ICarerAllocation {
    return event.carers.find((ca) => {
      return ca.person === aCarerId || (ca.allocatedByRun && !!ca.allocatedByRun.find((ra) => ra.person === aCarerId));
    });
  }

  private async handleClientNonManual(
    serviceUserId: string,
    method: string,
    queryparam: string
  ): Promise<void | boolean> {
    console.log(
      `handleClientNonManual(): serviceUserId = ${serviceUserId}; method = ${method}; queryParam = ${queryparam}`
    );
    if (!this.auth.schedulingMode()) {
      // we are using standalone eMAR
      return this.navigateByUrl(`/client-meds/${serviceUserId}?${queryparam}=1`);
    }
    // Check in / check out of event, if there is one close enough
    const methodParams = this.auth.userInfo?.orgObj?.allowedLoggingMethods.find((m) => {
      return m.method === method;
    });
    if (!methodParams) {
      console.log("no logInOutMethodParams");
      return this.navigateByUrl(this.getHomePageUrl(this.auth));
    }
    const now = new Date();
    const nowVal = now.valueOf();
    const carerId = AuthService.getUserId(this, "handleClientNonManual()");
    const onTheFlyEventType = this.auth?.userInfo?.orgObj?.onTheFlyEventType;
    let matchingEvent: IScanMatch;
    let eventListToUse: IExpressedCarerEvent[];
    // Look for appropriate booking.  retrieveEventList() may yield two values, first from the cache and then
    // from the backend.  We don't want to process each result as this will cause us to redirect more than once
    // to the event-details page.  We would like to wait for the data from the server if it is needed (and if
    // it is available), however.
    for await (const yieldedEventList of this.carerEventProvider.retrieveEventList(
      carerId,
      new Date(nowVal - ONE_DAY),
      new Date(nowVal + ONE_DAY)
    )) {
      if (yieldedEventList) {
        eventListToUse = yieldedEventList;
      }
    }
    if (!eventListToUse) {
      // TODO MARK - This needs testing
      console.log("no eventList - offline?");
      return this.displayError(
        `Could not contact the server to find candidate events.  If you are offline, you may be still be able to check in manually.`
      );
    }
    const firstLoggedInto = eventListToUse.find((curr) => {
      const ourAlloc = this.findCarerOnEvent(carerId, curr);
      return (
        ourAlloc &&
        ourAlloc.actualStart?.time &&
        !ourAlloc.actualFinish?.time &&
        !curr?.clients?.some((c) => c.person === serviceUserId)
      );
    });
    if (firstLoggedInto) {
      let message = `You cannot check into a booking at the moment.<br><br>
You need to check out of this booking at ${firstLoggedInto.clients[0].title} ${firstLoggedInto.clients[0].givenName} ${firstLoggedInto.clients[0].familyName} first.`;
      if (BETA) {
        message += `<br><br>If this is incorrect please report it to support@reallycare.org`;
      }
      await this.displayError(message);
      return this.navigateByUrl(`/event-details/${BaseEventProviderService.getUniqueEventId(firstLoggedInto)}`);
    }
    let foundAdHoc = false;
    // try to find an event that this tag touch/qr code scan could relate to
    for (const evt of eventListToUse) {
      // the event needs to be for the client whose tag we have touched/qr code we have scanned
      if (!evt.clients?.some((c) => c.person === serviceUserId)) {
        continue;
      }
      // all events in eventListToUse should be assigned to us, so we should always find our carerAlloc
      const carerAlloc = this.findCarerOnEvent(carerId, evt);
      if (!carerAlloc) {
        continue;
      }
      // have we completed the event?  if so, there's nothing more for us to do here, so move on to check later events...
      if (carerAlloc.actualFinish) {
        continue;
      }
      const nowMs = new Date().valueOf();
      // could we be logging into this event?
      if (!carerAlloc.actualStart) {
        // Are we in range for an NFC login?
        // Note we don't care about ad hoc bookings here, since they cannot exist without being logged in
        // *Simon adds: actually, that is not technically true - you can cancel the log in to an ad hoc event,
        // leaving it without an actual start.  however, it seems fair game to require them to manually check into
        // the ad hoc event if they do that
        const plannedStartVal = new Date(evt.plannedStart).valueOf();
        if (
          (methodParams.advanceLoggingLimit === 0 ||
            nowMs > plannedStartVal - (methodParams.advanceLoggingLimit + methodParams.defEventStartAdj) * 60000) &&
          (methodParams.retrospectiveLoggingLimit === 0 ||
            nowMs < plannedStartVal + (methodParams.retrospectiveLoggingLimit - methodParams.defEventFinishAdj) * 60000)
        ) {
          matchingEvent = { evt, type: "start" };
          break;
        }
        continue;
      }
      // We have a start but we don't have a finish.  So, could we be logging out of this event?
      if (isAdHoc(evt)) {
        foundAdHoc = true;
        // For an ad hoc event, we just check enough time has elapsed to ensure it is not an accidental double scan
        if (
          carerAlloc?.actualStart?.recordedAt &&
          nowVal > carerAlloc?.actualStart?.recordedAt.valueOf() + ONE_MINUTE
        ) {
          matchingEvent = { evt, type: "finish" };
          break;
        }
      } else {
        // whilst for anything else, we need to check that we are in range
        const plannedStart = new Date(carerAlloc.actualStart.time).valueOf();
        const plannedFinish = plannedStart + evt.plannedDuration * 60000;
        if (
          nowMs > plannedStart &&
          nowMs < plannedFinish + (methodParams.retrospectiveLoggingLimit - methodParams.defEventFinishAdj) * 60000
        ) {
          matchingEvent = { evt, type: "finish" };
          break;
        }
      }
    }
    // we found an event for them to check into or out of
    if (matchingEvent) {
      const e = matchingEvent.evt;
      console.log(`matchingEvent found: ${JSON.stringify(e)}`);
      const routeId = isAdHoc(e) ? e.uniqueEventId : BaseEventProviderService.getUniqueEventId(e);
      return this.navigateByUrl(`/event-details/${routeId}/${method}/${matchingEvent.type}`);
    }
    // we didn't, but we already have an ad hoc event for this client, and it is too early for us to touch out of it
    // this seems likely to be an accidental double tag touch.  just ignore it - we certainly don't want to create a 2nd ad hoc
    if (foundAdHoc) {
      return;
    }
    // we didn't... but we are configured to create an ad hoc event
    if (onTheFlyEventType) {
      // we need the service user so we can grab their name etc.
      const clientData = await this.currentClientProvider.getCurrentClientDets(serviceUserId);
      if (clientData) {
        const evt = await this.carerEventProvider.createAndCacheAdHocEvent(carerId, clientData.obj, onTheFlyEventType);
        return this.navigateByUrl(`/event-details/${evt.uniqueEventId}/${method}/start`);
      } else {
        return this.displayError(
          `No such client record found to create on-the-fly event - suggest you log out and back in again to refresh.`
        );
      }
    }
    // we're out of options
    console.log("no matchingEvent found");
    return this.displayError(`There are no bookings that match the criteria for using ${method}.  Try a manual match.`);
  }

  private handleClientScan(data: ScanResult) {
    this.handleClientNonManual(data.content, "QR Code", "qr").catch((e) =>
      this.errorHandler.handleUnexpected(e, ["handleClientScan", "handleClientNonManual"])
    );
  }

  private handleClientTagTouch(data: any) {
    if (Array.isArray(data)) {
      data = data[0];
    }
    this.handleClientNonManual(data.serviceUser._id, "NFC", "nfc").catch((e) =>
      this.errorHandler.handleUnexpected(e, ["handleClientTagTouch", "handleClientNonManual"])
    );
  }

  public cachedWrites(): boolean {
    // To force the write cache to be cleared (useful if it has become corrupt),
    // uncomment the following line (and one in clearCache())...
    // return false;
    const writeCacheInfo = this.writeCache.getWriteCacheInfo();
    return !!(writeCacheInfo.untried + writeCacheInfo.inFlight + writeCacheInfo.retries);
  }

  public async cacheIsEmpty(): Promise<boolean> {
    return (await this.readCache.getAllCacheKeys()).length === 0;
  }

  private getSingletonCaches(): SingletonProvider<any>[] {
    return [this.writeCache, this.eventUpdatesCache, this.releaseCache];
  }

  public async clearCacheAsync(preserveSingletonData: boolean): Promise<void> {
    captureMessage("Cache cleared");
    await this.readCache.clear(this.getSingletonCaches(), preserveSingletonData);
    this.historicEventLogProvider.cachedHistory = false;
  }

  public clearCacheAndLogout(preserveSingletonData: boolean): void {
    this.clearCacheAsync(preserveSingletonData).catch((e) =>
      this.errorHandler.handleUnexpected(e, ["PlaitMobileComponent", "clearCache"])
    );

    this.gotoLoginPage("");
  }

  public async showCache(): Promise<void> {
    const message = this.writeCache.getAllItems();
    await this.displayError(message, "Write Cache Content", "debugAlertCtrl");
  }

  public sendStatus() {
    throw new Error("Sending Status");
  }

  public handleLocalOption(option: MPlaitLocalMenuOption): void {
    this.menu.close().catch((e) => {
      this.errorHandler.handleUnexpected(e, ["handleLocalOption", "menu.close"]);
    });
    this.logger.log(
      `handleLocalOption called. page: ${option.instance?.constructor?.name}; title: ${option.title}; function: ${option.funcName}.`,
      LogLvl.Light
    );
    option.instance[option.funcName](option.params);
  }

  public cacheClearable(): void {
    this.cacheIsClearable = !this.cachedWrites() && this.carerEventProvider.getAdHocCount() === 0 && (this.backendService.networkStatus() || NetworkStatus.On) === NetworkStatus.On;
  }
}
