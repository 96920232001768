import { RCErrorHandler } from "../error.handler";
import { IonicStorageService } from "../services/ionicStorageService";
import { BaseProviderStorage } from "./baseProviderStorage";
import { LogLvl } from "./logger";

export abstract class SingletonProvider<T> extends BaseProviderStorage<T> {
  protected data: T;
  public abstract get storageKey(): string;
  protected abstract getDefault(): T;

  constructor(ionicStorage: IonicStorageService, errorHandler: RCErrorHandler) {
    super(ionicStorage, errorHandler);
    // we won't call this.retrieveData() ourself, because we cannot await that, and if we just fire off the call without awaiting,
    // we relinquish control of precisely when the data retrival will be completed during app initialisation.  
    // instead, a call to the retrieveData() method should be awaited at a suitable time - See app.component -> initializeApp()
    // we will however assign the default to this.data now.  I think this is only actually necessary to avoid problems with unit
    // tests (where initializeApp() is NOT called), but it's harmless to do it here and then again in this.retrieveData().
    this.assignDataDefault();
  }

  private assignDataDefault() {
    this.data = this.getDefault();
  }  

  public async retrieveData() {
    this.assignDataDefault();
    const data = await this.storage.getItem(this.storageKey);
    if (data) {
      this.data = data;
    } else {
      // store the default
      await this.updateStorage();
    }
  }

  public async updateStorage(): Promise<void> {
    this.logger.log(
      `updateStorage() called.  key: ${this.storageKey}; data: ${JSON.stringify(this.data)}`,
      LogLvl.Light
    );
    await this.storage.setItem(this.data, this.storageKey);
  }

  // This shouldn't be used - it has been added only for use by tests
  public async removeAll(): Promise<void> {
    await this.storage.removeAll();
    await this.retrieveData();
  }
}
