import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { ObservableEventsService } from "../../services/observableEventsService";
import { LeanPerson } from "../../models/people/leanPerson";
import { TagOwner, TagOwnerProviderService } from "../data/tag-owner-provider.service";
import { AuthService } from "../data/auth.service";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { FullPerson } from "../../models/people/fullPerson";
import { ICurrentClientType } from "../data/current-client-provider.service";
import { RCErrorHandler } from "../../error.handler";

const nfcDebug = true;

function nfcLog(logStr: string): void {
  if (nfcDebug) {
    // eslint-disable-next-line no-console
    console.log(logStr);
  }
}

@Injectable({
  providedIn: "root",
})
export class NFCService {
  private nfc: any;
  public readTagFor: LeanPerson;

  constructor(
    public tagOwnerProvider: TagOwnerProviderService,
    public platform: Platform,
    public auth: AuthService,
    public observableEvents: ObservableEventsService,
    private fullPersonCache: ReadCacheService<FullPerson>,
    private allClientCache: ReadCacheService<ICurrentClientType>,
    private errorHandler: RCErrorHandler
  ) {}

  public initialise(): void {
    // Check that we are on a device that supports nfc
    if (this.platform.is("cordova")) {
      // Check that we are on a device that supports nfc
      this.nfc = window["nfc"];
      if (this.nfc) {
        window["nfc"]["NFCHandler"] = this;
        if (this.platform.is("android")) {
          this.addListeners();
        }
      } else {
        this.observableEvents.publish("nfcEvt", "Unable to initialise NFC");
      }
    }
  }

  private static bytesToHexString(bytes: Array<number>): string {
    let dec: number;
    let hexString: string;
    let bytesAsHexString = "";
    for (let i = 0; i < bytes.length; i++) {
      if (bytes[i] >= 0) {
        dec = bytes[i];
      } else {
        dec = 256 + bytes[i];
      }
      hexString = dec.toString(16);
      // zero padding
      if (hexString.length === 1) {
        hexString = "0" + hexString;
      }
      bytesAsHexString += ":" + hexString;
    }
    return bytesAsHexString.slice(1).toUpperCase();
  }

  private addListeners(): void {
    [
      { n: "addTagDiscoveredListener", t: "discovered" },
      { n: "addNdefListener", t: "NDEF" },
    ].forEach((regDetails) => {
      this.nfc[regDetails.n](
        (nfcEvent: any) => {
          const tagId = NFCService.bytesToHexString(nfcEvent.tag.id);
          if (this.readTagFor) {
            this.tagOwnerProvider
              .assignAssets(this.readTagFor, tagId)
              .catch((e) =>
                this.errorHandler.handleUnexpected(e, ["NFCService", "addListeners", "tagOwnerProvider.assignAssets"])
              );
            this.readTagFor = undefined;
          } else {
            this.handleTag(tagId).catch((e) =>
              this.errorHandler.handleUnexpected(e, ["NFCService", "addListeners", "handleTag"])
            );
          }
        },
        () => {
          nfcLog(`Listening for ${regDetails.t} tags`);
        },
        (reason) => {
          this.observableEvents.publish("nfcEvt", `Failed to set up listener for ${regDetails.t}.  Reason: ${reason}`);
        }
      );
    });
  }

  public haveNFC(): boolean {
    return !!(window as any).nfc;
  }

  private async processTouch(data: TagOwner, tagId: string) {
    if (data.client?.isClient) {
      this.observableEvents.publish("tag:client", { serviceUser: data, nfc: true });
    } else if (data.careWorker?.isCareWorker || data.sysUserData?.roles?.includes("admin")) {
      await this.auth.nfcAuth(data, tagId);
    } else {
      // Just a random NFC tag?
      nfcLog("Tag not found");
    }
  }

  public async handleTag(tagId: string) {
    nfcLog("HandleTag");
    /**
     * There are several places we can look for a tagId in the cache, and it is safer to do that than call
     * getChangeableId, which will fail if there is no network.
     * So we will look for the tag in pl.* and cc.* first
     */
    const people = await this.fullPersonCache.getAll(CacheKeyPrefix.PersonLookup);
    const person = people.find((p) => p?.obj?.tagId === tagId);
    if (person) {
      return this.processTouch(person.obj, tagId);
    }
    const clients = await this.allClientCache.getAll(CacheKeyPrefix.CurrentClientType);
    const client = clients.find((p) => p?.obj?.tagId === tagId);
    if (client) {
      return this.processTouch(client.obj, tagId);
    }
    nfcLog(tagId);
    for (const nonMatch of clients) {
      nfcLog(JSON.stringify(nonMatch.obj, null, 2));
    }
    try {
      for await (const data of this.tagOwnerProvider.getFromTag(tagId)) {
        await this.processTouch(data.data, tagId);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error reading client data! " + JSON.stringify(err.message, null, 2));
    }
  }

  public scanIphone(overrideHandler?: (tagString: string) => void) {
    //   this.nfc.scanTag().then(
    //     (tag) => {
    //       const tagId = NFCService.bytesToHexString(tag.id);
    //       if (this.readTagFor) {
    //         this.tagOwnerProvider.assignAssets(this.readTagFor, tagId);
    //         this.readTagFor = undefined;
    //         if (overrideHandler) {
    //           overrideHandler(tagId);
    //         }
    //       } else {
    //         void this.handleTag(tagId);
    //       }
    //     },
    //     (err) => {
    //       if (nfcDebug) {
    //         console.log(`Error scanning NFC on iphone ${err}`);
    //       }
    //     }
    //   );
  }
}
