import { Injectable } from "@angular/core";

import { Collection } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { ImmutableBackendProvider } from "../../lib/immutableBackendProvider";
import { RCErrorHandler } from "../../error.handler";

export interface IParentTask {
  notes: string;
}

@Injectable({
  providedIn: "root",
})
export class ParentTaskProviderService extends ImmutableBackendProvider<IParentTask> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IParentTask>,
    writeCache: WriteCacheService
  ) {
    super(backEnd, errorHandler, observableEvents, readCache, writeCache, CacheKeyPrefix.ParentTask, Collection.None);
  }
}
