export enum DebugLvl {
  Error = 0,
  Light = 1,
  Heavy = 2,
  Insane = 3,
}

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;

export const ONE_DAY_IN_MINS = 24 * 60;
