import { Injectable } from "@angular/core";

import * as constants from "../constants";

import { BackendService } from "../services/backend.service";
import { CacheKeyPrefix } from "./providerWithCaching";
import { ObservableEventsService } from "../services/observableEventsService";
import { WriteCacheService } from "../services/writeCacheService";
import { CacheData, ReadCacheService } from "../services/readCacheService";
import { BackendProvider, Collection, DataSource, IIdentified, URLType } from "./backendProvider";
import { RCErrorHandler } from "../error.handler";

export interface IInMemoryStore<T> {
  [id: string]: T;
}

export type CachedRecordIds = { updatedAt: number; id: string }[];

export interface ITimestamped {
  updatedAt: Date;
}

@Injectable({
  providedIn: "root",
})
export class ImmutableBackendProvider<T> extends BackendProvider<T> {
  // reading from storage is relatively slow.  as the number of records of type T that we expect to need during any given session
  // is relatively small, we'll store each record that we retrieve - either from storage, or from the backend - in here, providing
  // extrmely fast access for repeat requests
  protected inMemoryStore: IInMemoryStore<T>;

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<T>,
    writeCache: WriteCacheService,
    cacheKeyPrefix: CacheKeyPrefix,
    collection: Collection
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      cacheKeyPrefix,
      collection,
      constants.ONE_WEEK * 4, // keep data in the cache for a maximum of four weeks
      constants.ONE_WEEK // but if possible, refresh it after a week
    );
  }

  public getFromInMemoryStore(id: string) {
    return this.inMemoryStore?.[id];
  }

  public internalGetRecord(
    id: string,
    urlType = URLType.Standard,
    urlPattern?: string
  ): Promise<{ data: T; source: DataSource }> {
    const data = this.getFromInMemoryStore(id);
    // by using super.getImmutable, we will just one value - either from the cache (if we have the data, and the
    // cache entry has not expired), or from the backend.  This disguises the use of AsyncGenerators, simplifying the API
    // for immutable data types.
    return data
      ? Promise.resolve({ data, source: DataSource.InMemory })
      : super.getImmutable(id, urlType, urlPattern).then((value: { data: T; source: DataSource }) => {
          // it is possible that the cache is empty (or expired) and the backend returns nothing
          if (value) {
            // having retrieved the data from the cache / backend, stash it in this.inMemoryStore for faster
            // access next time
            if (!this.inMemoryStore) {
              this.inMemoryStore = {};
            }
            this.inMemoryStore[id] = value.data;
          }
          return value;
        });
  }

  // return the record, requesting it from the backend if necessary
  public async getRecord(id: string): Promise<T> {
    const result = await this.internalGetRecord(id);
    return result.data;
  }

  // return the record, but DON'T request it from the backend if it is not already cached
  public async getCachedRecord(id: string, throwOnCacheMiss = true): Promise<T> {
    return this.getFromInMemoryStore(id) || super.getCachedImmutable(id, throwOnCacheMiss);
  }

  // return a number of records from the cache, NOT requesting anything from the backend and NOT
  // generating errors for any records found not to be already cached
  public async getCachedRecords(ids: string[]): Promise<T[]> {
    return Promise.all(ids.map((id) => this.getCachedRecord(id, false)));
  }

  public cacheRecords(records: IInMemoryStore<T>): Promise<CacheData<T>[]> {
    if (!this.inMemoryStore) {
      this.inMemoryStore = records;
    } else {
      for (const key in records) {
        this.inMemoryStore[key] = records[key];
      }
    }

    const promises: Promise<CacheData<T>>[] = [];
    // tslint:disable-next-line:forin
    for (const key in records) {
      promises.push(this.readCache.store(records[key], this.getCacheKey(key)));
    }
    return Promise.all(promises);
  }
}

export class TimestampedImmutableBackendProvider<
  T extends IIdentified & ITimestamped
> extends ImmutableBackendProvider<T> {
  public async getCachedRecordIdsAndTimestamps(ids: string[]): Promise<CachedRecordIds> {
    const cachedRecords = await this.getCachedRecords(ids);
    return cachedRecords
      .filter((r) => !!r) // get rid of ids that getCachedRecords() has failed to convert to a cached record, which happens the first time we log in after something has expired
      .map((r) => {
        return { id: r._id, updatedAt: r.updatedAt?.valueOf() };
      });
  }
}
