import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/capacitor";

export enum LogLvl {
  Error = 0,
  Light = 1,
  Heavy = 2,
  Insane = 3,
}

export class Logger {
  private amInterested(lvl: LogLvl): boolean {
    return lvl <= (environment.providerDebuggingLevel || LogLvl.Light);
  }

  public log(msg: string, lvl: LogLvl) {
    if (this.amInterested(lvl)) {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  }

  public sentryLog(msg: string, lvl: LogLvl) {
    if (this.amInterested(lvl)) {
      Sentry.captureMessage(msg);
    }
  }
}
