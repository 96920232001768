import { ExpandableClient } from "../../pages/event-details/event-details.page";
import { IEventLog, IExpressedCarerAllocation, IExpressedEvent } from "../../providers/data/event-provider.service";
import { ICombinedTaskCompletions } from "./eventLogging";
import { Address, FullPerson } from "../people/fullPerson";
import { PersonProviderService } from "../../providers/data/person-provider.service";
import { UnexpectedLocalError } from "../../error.handler";

export function formatTasks(clients: ExpandableClient[]): ICombinedTaskCompletions {
  const result: ICombinedTaskCompletions = {};
  for (const client of clients) {
    const combined = [...client.tasks.completed, ...client.tasks.unableToComplete];
    if (combined.length === 0) {
      continue;
    }
    result[client.person._id] = combined;
  }
  return result;
}

export function formatLogs(userId: any, clients: ExpandableClient[]): IEventLog[] {
  const results: IEventLog[] = [];
  const submissionDate = new Date();
  for (const client of clients) {
    if (client.logWordCount > 0) {
      results.push(
        // add additional information to the IWorkingEventLog required by the backend
        Object.assign(
          {
            author: userId,
            subject: client.person._id,
            submissionDate,
          },
          client.logs[0]
        )
      );
    }
  }
  return results;
}

export async function getPointOfCareAddress(
  event: IExpressedEvent,
  personProvider: PersonProviderService
): Promise<Address> {
  let firstClient: FullPerson;
  if (event.clients.length > 0) {
    firstClient = await personProvider.getRecord(event.clients[0].person);
  }
  if (!firstClient) {
    throw new Error("unknown client");
  }
  let homeAddress: Address;
  if (firstClient.addressList) {
    homeAddress = firstClient.addressList.find((a) => a.type === "Home");
  }
  if (!homeAddress) {
    throw new Error("unknown address");
  }
  return homeAddress;
}

export function findCarerAllocAddingOneForRunAssignedCarers(
  event: IExpressedEvent,
  carerId: string
): IExpressedCarerAllocation {
  let alloc = event.carers.find((c) => c.person === carerId);
  if (!alloc) {
    // If they're not directly-allocated to the event, see whether they are allocated by run.
    // If they are, take the details from that by-run allocation and create a "fake"
    // direct allocation from them
    for (const carerAlloc of event.carers) {
      if (!carerAlloc.allocatedByRun) {
        continue;
      }
      for (const byRun of carerAlloc.allocatedByRun) {
        if (byRun.person === carerId) {
          alloc = {
            person: byRun.person,
            contract: byRun.contract,
            title: byRun.title,
            givenName: byRun.givenName,
            familyName: byRun.familyName,
            // If the carer logs into this event, this new allocation object is what will
            // ultimately be materialised at the backend.  The existence of both a runId and a personId
            // tells the world that this is a materialised instance of an allocation by run.
            run: carerAlloc.run,
            shortName: carerAlloc.shortName,
            longName: carerAlloc.longName,
          };
          event.carers.push(alloc);
        }
      }
    }
  }
  if (!alloc) {
    throw new UnexpectedLocalError(`Carer is not allocated to event`, { carerId, eventId: event.eventId });
  }
  return alloc;
}
