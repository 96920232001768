import { Injectable } from "@angular/core";

import * as Sentry from "@sentry/capacitor";
import * as jsondiffpatch from "jsondiffpatch";

import * as constants from "../../constants";

import findLastIndex from "lodash-es/findLastIndex";
import cloneDeep from "lodash-es/cloneDeep";

import { BackendProvider, Collection, URLType } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { LogLvl } from "../../lib/logger";
import { ICareplan, ICareplanStructure } from "../../lib/careplan";
import { ObservableEventsService } from "../../services/observableEventsService";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService } from "../../services/backend.service";
import { CareplanStructureProviderService, NotYetCachedCareplanStructure } from "./careplan-structure-provider.service";
import { PersonProviderService } from "./person-provider.service";
import { IDatesByClient } from "./carer-event-provider.service";
import { ClientCareplan, DecoratedClientCareplan } from "../../models/people/fullPerson";
import { ERR_REFRESH_CACHE, ERR_REPORT_TO_OFFICE, RCErrorHandler, UnexpectedLocalError } from "../../error.handler";

export const NO_DIFFERENCE = "[none]";

export interface NotYetCachedCareplan extends ICareplan {
  _id: string;
}

interface ICareplansAndStructures {
  careplans: NotYetCachedCareplan[];
  structures: NotYetCachedCareplanStructure[];
}
interface ICareplansByStructure {
  [structureId: string]: string[];
}

@Injectable({
  providedIn: "root",
})
export class CareplanProviderService extends BackendProvider<ICareplan> {
  public haveClearedOldCareplans: boolean; // we'll do it only once per login - achieved by (re)setting this to false from the home page constructor

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<ICareplan>,
    writeCache: WriteCacheService,
    private personProvider: PersonProviderService,
    private careplanStructureProvider: CareplanStructureProviderService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.Careplan,
      Collection.Careplan,
      constants.ONE_WEEK * 208, // keep data in the cache for a maximum of 4 years
      constants.ONE_WEEK * 208 // and never refresh it
    );
  }

  public async decorateCareplanList(list: ClientCareplan[]): Promise<DecoratedClientCareplan[]> {
    // Partial because we'll be assigning the structure later
    const results: Partial<DecoratedClientCareplan>[] = [];
    const structureIds = new Set<string>();
    for (const listEntry of list) {
      const careplan = await this.getCachedImmutable(listEntry.data, false);
      // if we don't have this careplan in the cache, the likely scenario is that it is too old to matter
      // (which means, it - and all of the other careplans sharing the same structure - were succeeded by
      // a more recent careplan before the first event that is currently accessible for this client).  In
      // this scenario, we simply don't put that careplan into the decorated list, which means the user
      // will not have access to it, even when scrolling back.
      if (!careplan) {
        continue;
      }
      const structureId = careplan._structure;
      structureIds.add(structureId);
      results.push(Object.assign({ careplan, structureId }, listEntry));
    }
    // do all of the structure lookups just once, not only as this might be more efficient, but also because
    // we want items in results that share the same structure to point to the same structure object
    const structures: ICareplanStructure[] = [];
    for (const id of structureIds) {
      const structure = await this.careplanStructureProvider.getCareplanStructure(id);
      // we don't store them with an id (as this can be inferred from the cache key), but
      // we want the contents of structures to have ids so we can look up items from this list later
      structure._id = id;
      structures.push(structure);
    }
    const decoratedResults = results as DecoratedClientCareplan[];
    for (let i = 0; i < results.length; i++) {
      const thisResult = results[i];
      thisResult.structure = structures.find((s) => s._id === thisResult.structureId);
      if (i > 0) {
        if (!thisResult.careplan._diff) {
          const previousResult = results[i - 1];
          const previousStructure = previousResult.structure;
          const thisStructure = thisResult.structure;
          // if we don't have a _diff, but this and the previous revision share a structure (which shouldn't happen),
          // or have forked structures (in either direction - which is very likely to happen), then let's generate an
          // _onTheFly diff so we can show the user what changes between these two revisions.  we'll do
          // this for forked structures only because we can be fairly sure these will be similar.  (structures
          // that are not forked will presumably be very different, generating a diff that would be too unwieldy for
          // the user to make sense of.)
          if (
            thisStructure._id === previousStructure._id ||
            thisStructure.forkedFrom === previousStructure._id ||
            previousStructure.forkedFrom === thisStructure._id
          ) {
            const rebuilt = this.rebuildCareplan(decoratedResults, i - 1);
            const diffed = this.createDiffed(rebuilt.careplan, thisResult.careplan);
            thisResult.careplan._onTheFlyDiff = diffed._diff;
          }
        }
      }
    }
    return decoratedResults;
  }

  public async haveCachedCareplanForClient(serviceUserId: string): Promise<boolean> {
    const cachedPerson = await this.personProvider.getRecord(serviceUserId);
    const careplanList = cachedPerson.client?.careplanList || [];
    for (const item of careplanList) {
      if (item.data) {
        const cacheKey = this.getCacheKey(item.data);
        const careplan = await this.getCached(cacheKey);
        if (careplan) {
          return true;
        }
      }
    }
    return false;
  }

  public async preloadCache(earliestEventStartsByClient: IDatesByClient): Promise<void> {
    const uncachedCareplanIds: string[] = [];
    const cachedCareplanIds: string[] = [];
    const structuresWeNeed = new Set<string>();
    const careplansWeDontNeed: ICareplansByStructure = {};
    for (const clientId in earliestEventStartsByClient) {
      const cachedPerson = await this.personProvider.getRecord(clientId);
      const careplanList = cachedPerson.client?.careplanList;
      // don't sort the careplan list by effective date!  historically, we allowed careplan revisions to be
      // published out of order, which caused a big mess.  because successive revisions with the same structure
      // id are stored as diffs, we need them to be in the order in which they were created and NOT there
      // publication order, otherwise they'll fail to rebuild.  Note that Plait now requires effective dates to
      // come after those of all earlier revisions, so going forward, this will not be a problem (but this
      // also means that the careplan list will already be sorted!)
      // careplanList.sort((a, b) => a.effectiveDate.valueOf() - b.effectiveDate.valueOf());
      const firstIndexWeNeed = findLastIndex(
        careplanList,
        (ccp: ClientCareplan) => ccp.effectiveDate < earliestEventStartsByClient[clientId]
      );
      for (let i = 0; i < careplanList.length; i++) {
        const careplanId = careplanList[i].data;
        if (!careplanId) {
          // not sure how this can happen, but have seen it in Sentry
          // https://reallycare.sentry.io/issues/4396537472/events/ba4aee1e56b9414f933e193708dcd75d/
          continue;
        }
        const cacheKey = this.getCacheKey(careplanId);
        const cached = await this.getCached(cacheKey);
        const needIt = i >= firstIndexWeNeed;
        if (cached) {
          cachedCareplanIds.push(careplanId);
          if (!this.haveClearedOldCareplans) {
            const structureId = cached.obj._structure;
            // should always be the case, but we might as well check...
            if (structureId) {
              if (needIt) {
                structuresWeNeed.add(structureId);
              } else {
                if (!careplansWeDontNeed[structureId]) {
                  careplansWeDontNeed[structureId] = [];
                }
                careplansWeDontNeed[structureId].push(careplanId);
              }
            }
          }
        } else if (needIt) {
          uncachedCareplanIds.push(careplanId);
        }
      }
    }
    if (!this.haveClearedOldCareplans) {
      // fire this off but there's no need to await it, and if there is an error, we don't want to disturb
      // the user with it
      this.clearOldCareplans(careplansWeDontNeed, [...structuresWeNeed]).catch((err) => {
        try {
          this.haveClearedOldCareplans = true; // so we don't keep on trying
          Sentry.captureException(err);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
    }

    if (uncachedCareplanIds.length === 0) {
      return;
    }
    // to force it to match on a server path which expects three values, we need to pass something even for parameters
    // that we don't have any ids for right now
    if (cachedCareplanIds.length === 0) {
      cachedCareplanIds.push("none");
    }
    const cachedStructureIds = await this.careplanStructureProvider.getAllCachedStructureIds();
    if (cachedStructureIds.length === 0) {
      cachedStructureIds.push("none");
    }
    this.logger.log(
      `CareplanProviderService.preloadCache() ${uncachedCareplanIds.length} uncached careplans identified.  Requesting from backend...`,
      LogLvl.Light
    );
    const uncachedCareplansStr = uncachedCareplanIds.join(",");
    const cachedCareplansStr = cachedCareplanIds.join(",");
    const cachedStructuresStr = cachedStructureIds.join(",");
    const url = `careplansForPhoneRequest/${uncachedCareplansStr}/${cachedCareplansStr}/${cachedStructuresStr}`;
    const careplansAndStructures: ICareplansAndStructures = await this.getFromBackEnd(URLType.Extended, url);
    if (!careplansAndStructures) {
      return; // offline
    }
    this.logger.log(
      `CareplanProviderService.preloadCache() ${careplansAndStructures.careplans.length} uncached careplans and ${careplansAndStructures.structures.length} uncached structures received.  Beginning to cache...`,
      LogLvl.Light
    );
    await this.careplanStructureProvider.preloadCache(careplansAndStructures.structures);
    await this.cacheCareplans(careplansAndStructures.careplans);
    this.logger.log(`CareplanProviderService.preloadCache() finished caching.`, LogLvl.Light);
  }

  private async cacheCareplans(careplans: NotYetCachedCareplan[]): Promise<void> {
    for (const careplan of careplans) {
      const cacheKey = this.getCacheKey(careplan._id);
      delete careplan._id;
      await this.readCache.store(careplan, cacheKey);
    }
  }

  private async clearOldCareplans(
    careplansWeDontNeed: ICareplansByStructure,
    structuresWeNeed: string[]
  ): Promise<void> {
    for (const structureId in careplansWeDontNeed) {
      if (structuresWeNeed.includes(structureId)) {
        continue;
      }
      for (const careplanId of careplansWeDontNeed[structureId]) {
        const careplanCacheKey = this.getCacheKey(careplanId);
        await this.readCache.remove(careplanCacheKey);
      }
      await this.careplanStructureProvider.removeItem(structureId);
    }
    this.haveClearedOldCareplans = true; // only want to do it once per login
  }

  // this function almost identical to a same-named function in server/api/careplan.careplan.lib.ts
  private separateUserDefinedDataFromInternalFields(careplan: ICareplan): {
    internal: ICareplan;
    userDefinedData: any;
  } {
    const internal: Partial<ICareplan> = {};
    const userDefinedData: any = {};
    for (const prop in careplan) {
      if (prop.startsWith("_")) {
        internal[prop] = careplan[prop];
      } else {
        userDefinedData[prop] = careplan[prop];
      }
    }
    return {
      internal: internal as ICareplan,
      userDefinedData,
    };
  }

  // this function is very similar to createDiffed in server/api/careplan.careplan.lib.ts
  public createDiffed(fromThis: ICareplan, toThis: ICareplan): ICareplan {
    const previousParts = this.separateUserDefinedDataFromInternalFields(fromThis);
    const ourParts = this.separateUserDefinedDataFromInternalFields(toThis);
    const result: Partial<ICareplan> = ourParts.internal;
    const jdp = jsondiffpatch.create({
      objectHash: function (obj: any, index: number) {
        // intentionally uncommented.  comments can be found in the module mentioned above.
        if (obj._xid) {
          return obj._xid;
        }
        if (obj.x) {
          return obj.x; // shouldn't happen
        }
        if (obj._id?.toString) {
          return obj._id.toString(); // shouldn't happen
        }
        return index; // shouldn't happen
      },
    });
    result._diff = jdp.diff(previousParts.userDefinedData, ourParts.userDefinedData) || NO_DIFFERENCE;
    return result as ICareplan;
  }

  private fail(error: string) {
    throw new UnexpectedLocalError(
      `Sorry, this careplan cannot be displayed right now due to the required document(s) not being found in the cache, or a cache corruption. ${ERR_REFRESH_CACHE} ${ERR_REPORT_TO_OFFICE}`,
      { "Error condition": error }
    );
  }

  public rebuildCareplan(
    list: DecoratedClientCareplan[],
    idx: number
  ): { careplan: ICareplan; structure: ICareplanStructure } {
    const listItem = list[idx];
    let careplan: ICareplan;
    const structure = listItem.structure;
    if (!listItem.careplan._diff) {
      careplan = listItem.careplan;
    } else {
      let baseRevisionIndex: number;
      for (let i = idx - 1; i >= 0; i--) {
        if (list[i].structureId === structure._id) {
          baseRevisionIndex = i;
        } else {
          break;
        }
      }
      if (baseRevisionIndex === undefined) {
        if (listItem.careplan._diffIsWhatTheyDontAlreadyKnow && idx > 0) {
          // when a call is made to showCareworkerWhatTheyDontAlreadyKnow(), a diff is created between the revision that's effective at the
          // time of the clicked link, and the previous one.  this is used to show the carer what's changed between the these two revisions of
          // the careplan, highlighting what they don't already know.  if the two revisions for which this one-time-only _diff is created
          // don't share the same structure (but the later structure is a fork of the earlier one), assigning a _diff in that way will cause
          // us to reach here, because we'll have failed to find a "base" revision for the careplan at idx.  in this very special case, our
          // base revision is the previous revision (i.e., idx - 1).
          baseRevisionIndex = idx - 1;
        } else {
          /* eslint-disable no-console */
          console.log(`Structure: ${structure._id} (${structure.description})`);
          console.log(`List: ${list.map((li, i) => `${i} - ${li.structureId} (${li.effectiveDate})`)}`);
          console.log(`List[${idx}].careplan: ${JSON.stringify(listItem.careplan)}`); // probably going to be truncated, but might be useful
          /* eslint-enable no-console */
          this.fail("no patches");
        }
      }
      careplan = cloneDeep(list[baseRevisionIndex].careplan);
      if (careplan._diff) {
        this.fail("no base");
      }
      for (let i = baseRevisionIndex + 1; i <= idx; i++) {
        const diff = list[i].careplan._diff;
        if (!diff) {
          this.fail(`no patch at index ${i}`);
        }
        if (diff !== NO_DIFFERENCE) {
          // don't know why it's necessary to clone the diff here, but jsondiffpatch.patch appears to modify it sometimes
          jsondiffpatch.patch(careplan, cloneDeep(diff));
        }
      }
      // because rebuilt started from an earlier revision, we need to explicitly assign anything from
      // the displayed revision that will not have been part of the diff patches
      const displayedRev = list[idx].careplan;
      careplan._diff = cloneDeep(displayedRev._diff);
      careplan._effectiveFrom = displayedRev._effectiveFrom;

      // we got into here because we have a _diff.  if we have a _diff, we shouldn't have an _onTheFlyDiff,
      // and if we do, it will be because it came from the earlier revision that we started from
      if (careplan._onTheFlyDiff) {
        delete careplan._onTheFlyDiff;
      }
    }
    return { careplan, structure };
  }
}
