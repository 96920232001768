import { BaseStringStorage } from "./baseStorage";
import { LogLvl } from "./logger";

export class LocalStorage<T> extends BaseStringStorage<T> {
  // This class is currently logging every operation, in an attempt to prove that LocalStorage is sometimes
  // being used in production (when IonicStorage should always be used instead).  As this class isn't supposed to be
  // used in production, it's probably OK to leave this in, although it might be sensible to tidy this up in 2024.
  public async getRaw(itemKey: string): Promise<string> {
    this.logger.log(`LocalStorage.getRaw (${itemKey}) called...`, LogLvl.Light);
    return window.localStorage.getItem(itemKey);
  }

  public async getAllKeys(): Promise<string[]> {
    this.logger.log(`LocalStorage.getAllKeys`, LogLvl.Light);
    const keys: string[] = [];
    for (let i = 0; i < window.localStorage.length; i++) {
      keys.push(window.localStorage.key(i));
    }
    return keys;
  }

  public async setRaw(raw: string, itemKey: string): Promise<void> {
    this.logger.log(`LocalStorage.setRaw (${itemKey}) called...`, LogLvl.Light);
    window.localStorage.setItem(itemKey, raw);
  }

  public async removeItem(itemKey: string): Promise<void> {
    this.logger.log(`LocalStorage.removeItem (${itemKey}) called...`, LogLvl.Light);
    window.localStorage.removeItem(itemKey);
  }

  // Use with extreme caution!!  All instances of this class will be affected.
  public async removeAll(): Promise<void> {
    this.logger.log(`LocalStorage.removeAll called...`, LogLvl.Light);
    window.localStorage.clear();
  }
}
