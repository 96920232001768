import { BaseStorage } from "./baseStorage";
import { LocalStorage } from "./localStorage";
import { IonicStorage } from "./ionicStorage";
import { Logger } from "./logger";
import { IonicStorageService } from "../services/ionicStorageService";
import { RCErrorHandler } from "../error.handler";

export class BaseProviderStorage<T> {
  protected storage: BaseStorage<T>;
  protected logger: Logger;

  constructor(ionicStorage: IonicStorageService, protected errorHandler: RCErrorHandler) {
    // when testing, many (perhaps all?) of the .specs do this:
    //   { provide: IonicStorageService, useValue: undefined }
    // ...which enables us to default to local storage when testing.  when actually
    // running the app, angular will inject ionicStorageService which we should then use, thus:
    if (ionicStorage) {
      this.storage = new IonicStorage<T>(ionicStorage);
    } else {
      this.storage = new LocalStorage<T>();
    }
    this.logger = new Logger();
  }
}
