import { Injectable } from "@angular/core";

import { BackendProvider, Collection, IIdentified, URLType } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { IPersonName, IExpressedEvent, IEventLog } from "./event-provider.service";
import { ONE_DAY, ONE_HOUR } from "../../constants";
import { LogLvl } from "../../lib/logger";
import { RCErrorHandler } from "../../error.handler";

export interface IHistoricLog {
  eventId: string;
  eventStart: Date;
  submitted: Date;
  needsAlert: boolean;
  carer: IPersonName & IIdentified;
  text: string;
}
export interface IGroupedHistoricLogs {
  [clientId: string]: IHistoricLog[];
}

@Injectable({
  providedIn: "root",
})
export class HistoricEventLogProviderService extends BackendProvider<IHistoricLog[]> {
  cachedHistory: boolean;

  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IHistoricLog[]>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.HistoricEventLog,
      Collection.Event,
      ONE_DAY, // keep data in the cache for a maximum of one day
      ONE_HOUR // but if possible, refresh it after an hour (very conservative time between bookings)
    );
  }

  public async preloadCache(clientIds: string[]): Promise<void> {
    if (!this.cachedHistory) {
      this.cachedHistory = true;
      const url = `recentLogsForClients/${clientIds.join(",")}`;
      const groupedLogs: IGroupedHistoricLogs = await this.getFromBackEnd(URLType.Extended, url);
      if (!groupedLogs) {
        this.cachedHistory = false;
        return; // offline
      }
      const keys = Object.keys(groupedLogs);
      this.logger.log(`${keys.length} clients' historic event logs retrieved.  Beginning to cache...`, LogLvl.Light);
      for (const key of keys) {
        const cacheKey = this.getCacheKey(key);
        const logs = groupedLogs[key];
        await this.readCache.store(logs, cacheKey);
      }
      this.logger.log(`HistoricEventLogProviderService.preloadCache() finished caching.`, LogLvl.Light);
    }
  }

  public currentEventLogToHistoric(
    log: IEventLog,
    event: IExpressedEvent,
    author: IIdentified & IPersonName
  ): IHistoricLog {
    const cutDownAuthor: IPersonName & IIdentified = {
      givenName: author.givenName,
      familyName: author.familyName,
      title: author.title,
      preferredName: author.preferredName,
      _id: author._id,
    };
    const historic: IHistoricLog = {
      eventId: event.eventId,
      eventStart: event.plannedStart,
      submitted: log.submissionDate,
      needsAlert: log.needsAlert,
      carer: cutDownAuthor,
      text: log.log,
    };
    return historic;
  }

  public async cacheNewLog(
    log: IEventLog,
    event: IExpressedEvent,
    author: IIdentified & IPersonName
  ): Promise<IHistoricLog> {
    const historic = this.currentEventLogToHistoric(log, event, author);
    const clientId = log.subject;
    const logs = (await super.getCachedImmutable(clientId, false)) || [];
    logs.push(historic);
    await this.store(logs, clientId);
    return historic;
  }

  public async getFromCache(clientId: string, historicRelativeTo?: IExpressedEvent): Promise<IHistoricLog[]> {
    const logs = (await super.getCachedImmutable(clientId, false)) || [];
    if (historicRelativeTo) {
      return logs.filter(
        (l) => l.eventStart < historicRelativeTo.plannedStart && l.eventId !== historicRelativeTo.eventId
      );
    } else {
      return logs;
    }
  }
}
