import { ICareplan, ICareplanStructure } from "../../lib/careplan";

type Location = {
  coordinates: number[];
};

export interface ICareplanWindow {
  unawareFromIdx: number;
  lastEffectiveIdx: number;
}

type AddressType = "Home" | "Work";

export interface IAddress {
  line1: string;
  line2: string;
  line3: string;
  town: string;
  county: string;
  postcode: string;
  type: AddressType;
  loc: Location;
  accessInfo?: string;
}
export class Address implements IAddress {
  line1: string;
  line2: string;
  line3: string;
  town: string;
  county: string;
  postcode: string;
  type: AddressType;
  loc: Location;
  accessInfo: string;

  private delimitProps(props: string[], delimiter: string): string {
    let result = "";
    for (const prop of props) {
      const addressLine = this[prop];
      if (addressLine) {
        if (result !== "") {
          result += delimiter;
        }
        result += addressLine;
      }
    }
    return result;
  }

  public delimitLines(delimiter: string): string {
    return this.delimitProps(["line1", "line2", "line3", "town", "county", "postcode"], delimiter);
  }
}

export interface IPhone {
  type: "Landline" | "Mobile" | "Work" | "Fax";
  number: string;
}
export class Phone implements IPhone {
  type: "Landline" | "Mobile" | "Work" | "Fax";
  number: string;

  public asLink(textToDisplay?: string): string {
    if (this.number) {
      const tidiedNum = this.number.replace(/[^0-9+]/g, "");
      return `<a href="tel:${tidiedNum}">${textToDisplay || this.number}</a>`;
    } else {
      return "";
    }
  }
}

export interface IEmail {
  type: "Personal" | "Work";
  email: string;
}
export class Email implements IEmail {
  type: "Personal" | "Work";
  email: string;

  public asLink(textToDisplay?: string): string {
    return `<a href="mailto:${this.email}">${textToDisplay || this.email}</a>`;
  }
}

export interface IClientCareplan {
  effectiveDate: Date;
  data: string; // The id of the data (cached seperately by careplan-provider.service)
}
export class ClientCareplan implements IClientCareplan {
  effectiveDate: Date;
  data: string;
}

export interface IWorkerCareplanAwareness {
  workerId: string;
  awareOfCareplansUpTo: Date;
}
export class WorkerCareplanAwareness implements IWorkerCareplanAwareness {
  workerId: string;
  awareOfCareplansUpTo: Date;
}

export interface IPersonContract {
  contractStartDateTime: Date;
  contractFinishDateTime: Date;
  description: string;
}
export class PersonContract implements IPersonContract {
  contractStartDateTime: Date;
  contractFinishDateTime: Date;
  description: string;
}

// in fact, we will be provided with all of the data from the carer contract, but right now, we only use stuff
// that's part of PersonContract, so there's no need to declare anything more here yet...
export type ICarerContract = IPersonContract;
export class CarerContract extends PersonContract implements ICarerContract {}

export class DecoratedClientCareplan extends ClientCareplan {
  careplan: ICareplan;
  structureId: string;
  structure: ICareplanStructure;
}

export interface IClient {
  isClient: boolean;
  ssId?: string;
  NHSNumber?: string;
  warnings?: string;
  showWarningTextOnMobile?: boolean;
  showWarningIndicatorOnMobile?: boolean;
  allergies?: string;
  comments?: string;
  showCommentsOnMobile?: boolean;
  careplanList?: IClientCareplan[];
  workerCareplanAwareness?: IWorkerCareplanAwareness[];
  contractList?: IPersonContract[];
}
export class Client implements IClient {
  isClient = false;
  ssId?: string;
  NHSNumber?: string;
  warnings?: string;
  showWarningTextOnMobile?: boolean;
  showWarningIndicatorOnMobile?: boolean;
  allergies?: string;
  comments?: string;
  showCommentsOnMobile?: boolean;
  careplanList?: ClientCareplan[];
  workerCareplanAwareness?: WorkerCareplanAwareness[];
  contractList?: PersonContract[];

  public earliestContractStart(): Date {
    let result: Date;
    if (this.contractList) {
      for (const contract of this.contractList) {
        if (!contract.contractStartDateTime) {
          continue;
        }
        if (!result || contract.contractStartDateTime < result) {
          result = contract.contractStartDateTime;
        }
      }
    }
    return result;
  }

  public latestContractFinish(): Date {
    let result: Date;
    if (this.contractList) {
      for (const contract of this.contractList) {
        if (!contract.contractStartDateTime) {
          continue; // we're not interested in contracts that haven't got a start date
        }
        if (!contract.contractFinishDateTime) {
          return undefined; // if they have a contract that has a start date and no finish date then they're not considered to have a finish at all
        }
        if (!result || contract.contractFinishDateTime > result) {
          result = contract.contractFinishDateTime;
        }
      }
    }
    return result;
  }
}

export interface ICareWorker {
  isCareWorker: boolean;
  contractList?: IPersonContract[];
}
export class CareWorker implements ICareWorker {
  isCareWorker = false;
  contractList?: PersonContract[];
}

export interface IContact {
  contact: string;
  type: string;
  relationship: string;
}
export class Contact implements IContact {
  contact: string;
  type: string;
  relationship: string;
}

export enum IncludeTypeLabel {
  AlwaysInclude,
  NeverInclude,
  IncludeOnlyIfMultiple,
}

export interface IPerson {
  _id: string;
  familyName: string;
  givenName: string;
  title: string;
  sex?: string;
  birth?: Date;
  organisation?: string;
  tagId?: string;
  neverShowPhotoOnMobile?: boolean;
  numPhotos?: number;
  addressList?: IAddress[];
  phoneList?: IPhone[];
  emailList?: IEmail[];
  contactList?: IContact[];
  client: IClient;
  careWorker: ICareWorker;
  preferredName: string;
  updatedAt: Date;
}
export class FullPerson implements IPerson {
  familyName: string;
  givenName: string;
  title: string;
  sex?: string;
  birth?: Date;
  organisation?: string;
  tagId?: string;
  neverShowPhotoOnMobile?: boolean;
  numPhotos?: number;
  addressList?: Address[] = [];
  phoneList?: Phone[] = [];
  emailList?: Email[] = [];
  contactList?: Contact[] = [];
  client: Client;
  careWorker: CareWorker;
  preferredName: string;
  updatedAt: Date;

  /**
   * @param _id The mongo id of this person
   * @param createFromDocument Another object from which to set our initial property values.
   */
  constructor(
    // tslint:disable-next-line:variable-name
    public _id: string,
    createFromDocument?: IPerson
  ) {
    if (!createFromDocument) {
      this.client = new Client();
      this.careWorker = new CareWorker();
    } else {
      Object.assign(this, createFromDocument);
      this.client = new Client();
      if (createFromDocument.client) {
        Object.assign(this.client, createFromDocument.client);
      }
      this.careWorker = new CareWorker();
      if (createFromDocument.careWorker) {
        Object.assign(this.careWorker, createFromDocument.careWorker);
      }
      if (createFromDocument.addressList) {
        this.addressList = createFromDocument.addressList.map((a) => Object.assign(new Address(), a));
      }
      if (createFromDocument.phoneList) {
        this.phoneList = createFromDocument.phoneList.map((p) => Object.assign(new Phone(), p));
      }
      if (createFromDocument.emailList) {
        this.emailList = createFromDocument.emailList.map((e) => Object.assign(new Email(), e));
      }
      if (createFromDocument.contactList) {
        this.contactList = createFromDocument.contactList.map((c) => Object.assign(new Contact(), c));
      }
      if (createFromDocument.client?.contractList) {
        this.client.contractList = createFromDocument.client.contractList.map((c) =>
          Object.assign(new PersonContract(), c)
        );
      }
      if (createFromDocument.client?.careplanList) {
        this.client.careplanList = createFromDocument.client.careplanList.map((c) =>
          Object.assign(new ClientCareplan(), c)
        );
      }
      if (createFromDocument.careWorker?.contractList) {
        this.careWorker.contractList = createFromDocument.careWorker.contractList.map((c) =>
          Object.assign(new CarerContract(), c)
        );
      }
    }
  }

  public hasBirthdayToday(): boolean {
    return this.hasBirthdayOnADate(new Date());
  }

  public hasBirthdayOnADate(checkDate: Date): boolean {
    if (!this.birth) {
      return false;
    }
    const now = new Date(checkDate);
    const birthday = new Date(this.birth);
    return birthday.getUTCMonth() === now.getUTCMonth() && birthday.getUTCDate() === now.getUTCDate();
  }

  public fullName(): string {
    let name = ((this.title || "") + " " + (this.givenName || "")).trim() + " " + this.familyName;
    if (this.preferredName) {
      name += ` (${this.preferredName})`;
    }
    return name;
  }

  public delimitedPhoneNumbers(
    typesToInclude: string[],
    delimiter: string,
    includeTypeDescriptions: IncludeTypeLabel
  ): string {
    const matchingPhones = this.phoneList.filter((p) => typesToInclude.indexOf(p.type) > -1);
    let result = "";
    for (const phone of matchingPhones) {
      if (result !== "") {
        result += delimiter;
      }
      result += phone.asLink();
      if (
        includeTypeDescriptions === IncludeTypeLabel.AlwaysInclude ||
        (includeTypeDescriptions === IncludeTypeLabel.IncludeOnlyIfMultiple && matchingPhones.length > 1)
      ) {
        result += ` (${phone.type})`;
      }
    }
    return result;
  }

  public getCareplanWindowThatCareworkerIsUnawareOf(
    carerId: string,
    onlyCareplansEffectiveAt: Date,
    aList?: IClientCareplan[]
  ): ICareplanWindow {
    const awareUpTo = this.client?.workerCareplanAwareness?.find(
      (wca) => wca.workerId === carerId
    )?.awareOfCareplansUpTo;
    const list = aList || this.client?.careplanList;
    if (!list) {
      return { unawareFromIdx: -1, lastEffectiveIdx: -1 }; // shouldn't happen
    }
    const unawareFromIdx = list.findIndex(
      (cp) => cp.effectiveDate <= onlyCareplansEffectiveAt && (!awareUpTo || cp.effectiveDate > awareUpTo)
    );
    let lastEffectiveIdx = list.findIndex((cp) => cp.effectiveDate > onlyCareplansEffectiveAt);
    if (lastEffectiveIdx > 0) {
      lastEffectiveIdx--;
    } else {
      lastEffectiveIdx = list.length - 1;
    }
    return { unawareFromIdx, lastEffectiveIdx };
  }
}
