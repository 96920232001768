import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as jsondiffpatch from "jsondiffpatch";
import * as Sentry from "@sentry/capacitor";
import { WriteCacheService } from "./writeCacheService";
import { ObservableEventsService } from "./observableEventsService";
import { DOMAIN, environment } from "../../environments/environment";
import { DebugLvl, ONE_SECOND } from "../constants";
import { Logger, LogLvl } from "../lib/logger";
import { IonicStorageService } from "./ionicStorageService";
import { SingletonProvider } from "../lib/singletonProvider";
import { ERR_RECEIVED_EXPECTED_ERR, ExpectedError, LoggedOutError, MobileError, NoNetworkError, NoRetryUnexpectedError, RCErrorHandler, UnexpectedHttpError } from "../error.handler";

export interface ISimpleHeader {
  [p: string]: string | string[];
}

export enum Status {
  Off,
  On,
  NoAuth,
}

export enum NetworkStatus {
  Off,
  On,
}

export enum NoNetworkAction {
  Throw,
  Null,
}

interface IHttpPostParams {
  suppressErrorLogging?: boolean;
}

export interface ILastUpdated {
  date?: Date;
}

interface ICachedBackendStatus {
  networkStatus: NetworkStatus;
}

export class BackendStatusCache extends SingletonProvider<ICachedBackendStatus> {
  public get storageKey() {
    return "bs";
  }

  protected getDefault(): ICachedBackendStatus {
    return { networkStatus: NetworkStatus.Off };
  }

  get status(): ICachedBackendStatus {
    return this.data;
  }

  set status(status: ICachedBackendStatus) {
    this.data = status;
  }
}

@Injectable({
  providedIn: "root",
})
export class BackendService {
  private _backendStatus: Status = Status.NoAuth; // Because we are not authenticated initially
  private _networkStatus: NetworkStatus;
  private networkStatusCache: BackendStatusCache;
  private _bannerStatus: string;
  private tryForce: number;
  protected debuggingLevel: DebugLvl;
  protected logger: Logger;
  // only for testing - allows writes to be throttled, so we can more easily test the effect of stale incoming data
  public dontSendWrites = false;
  // only for testing - a refresh from the backend will be requested every time a cached item is retrieved,
  // so we can more easily test the requesting and processing of data from the server
  public immediateCacheRefresh = false;

  constructor(
    protected writeCache: WriteCacheService,
    private observableEvents: ObservableEventsService,
    private http: HttpClient,
    private errorHandler: RCErrorHandler,
    private ionicStorageService?: IonicStorageService
  ) {
    let lastWentOffline: Date;
    this.logger = new Logger();
    this.debuggingLevel = environment.providerDebuggingLevel || DebugLvl.Error;
    this.networkStatusCache = new BackendStatusCache(ionicStorageService, errorHandler);

    if (!observableEvents.mockForTesting) {
      observableEvents.subscribe("backend:nudge", () => {
        this._nextFlush().catch((e) =>
          this.errorHandler.handleUnexpected(e, ["BackendService", "backend:nudge", "_nextFlush"])
        );
      });
      observableEvents.subscribe("backend:auth-nudge", () => {
        this.backendStatus(Status.On);
        this._nextFlush().catch((e) =>
          this.errorHandler.handleUnexpected(e, ["BackendService", "backend:auth-nudge", "_nextFlush"])
        );
      });
      observableEvents.subscribe("writeCacheChange", (params?: { preventForcedFlush?: boolean }) => {
        this.connectStatus(params?.preventForcedFlush).catch((e) =>
          this.errorHandler.handleUnexpected(e, ["BackendService", "writeCacheChange", "connectStatus"])
        );
      });
    }
    setTimeout(async () => {
      window.addEventListener("offline", async () => {
        lastWentOffline = new Date();
        this.debugBackend(`onDisconnect`, DebugLvl.Heavy);
        this.networkStatus(NetworkStatus.Off);
        await this.onOffline();
      });

      window.addEventListener("online", () => {
        const ARBITRARY_PAUSE_LENGTH = 3000;
        this.debugBackend(`onConnect`, DebugLvl.Heavy);
        // We just got a connection.  However, we seem to need to wait briefly
        // before we determine the connection type. Might need to wait
        // prior to doing any api requests as well.
        setTimeout(async () => {
          // Check we haven't gone offline while waiting..
          if (!lastWentOffline || lastWentOffline.valueOf() + ARBITRARY_PAUSE_LENGTH < new Date().valueOf()) {
            this.debugBackend(`Setting network status to connected`, DebugLvl.Heavy);
            this.networkStatus(NetworkStatus.On);
            await this.onOnline();
          }
        }, ARBITRARY_PAUSE_LENGTH);
      });
      await this.connectStatus();
    }, 500);
  }

  protected debugBackend(msg: string, lvl = DebugLvl.Error) {
    if (lvl <= this.debuggingLevel) {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  }

  public backendStatus(value?: Status): Status {
    if (value !== undefined) {
      this.debugBackend(`Setting updateStatus to ${Status[value]}`, DebugLvl.Heavy);
      this._backendStatus = value;
    }
    return this._backendStatus;
  }

  private cacheBust(url: string, includeOrig = false): string {
    /* Prevent the service worker layer caching another copy of all the data */
    const connective = url.indexOf("?") === -1 ? "?" : "&";
    const retVal = `${includeOrig ? url : ""}${connective}ngsw-cache-bust=${Math.random()}`;
    return retVal;
  }

  public networkStatus(value?: NetworkStatus): NetworkStatus {
    if (value !== undefined) {
      this.debugBackend(`Setting network status to ${NetworkStatus[value]}`, DebugLvl.Heavy);
      this._networkStatus = value;
      this._bannerStatus = null;
      const currentCachedValue = this.networkStatusCache.status?.networkStatus;
      if (currentCachedValue !== value) {
        if (!this.networkStatusCache.status) {
          this.networkStatusCache.status = { networkStatus: value };
        } else {
          this.networkStatusCache.status.networkStatus = value;
        }
        this.networkStatusCache
          .updateStorage()
          .catch((e) =>
            this.errorHandler.handleUnexpected(e, [
              "BackendService",
              "networkStatus",
              "networkStatusCache.updateStorage",
            ])
          );
      }
    }
    return this._networkStatus;
  }

  public async restoreCachedNetworkStatus() {
    let status: NetworkStatus;
    if (this.networkStatusCache) {
      await this.networkStatusCache.retrieveData();
      status = this.networkStatusCache.status?.networkStatus;
    }
    if (status !== undefined) {
      this.networkStatus(status);
      await this.connectStatus();
    }
  }

  public async stashToken(token: string): Promise<void> {
    await this.ionicStorageService.setRaw(token, "token");
  }

  private async _getHeaders(addToken = true): Promise<ISimpleHeader | null> {
    const myHeaders: ISimpleHeader = {};
    myHeaders["Content-Type"] = "application/json";
    if (addToken) {
      const token = this.ionicStorageService ? await this.ionicStorageService.getRaw<string>("token") : "test token";
      if (token) {
        myHeaders.Authorization = `Bearer ${token}`;
      } else {
        this.logger.log(`No token found while preparing headers`, LogLvl.Light);
      }
    }
    return myHeaders;
  }

  private handleHttpError(err: any, verb: string, url: string, noNetworkAction = NoNetworkAction.Null) {
    // eslint-disable-next-line prefer-const
    let { actualError, message } = this.errorHandler.extractError(err);

    // even when extractError has found an alternative Error object, the status and statusText will probably still
    // be found in err (don't just do err.status || actualError.status, otherwise a value of 0 will be ignored)
    const errStatus = err.status >= 0 ? err.status : actualError.status;

    // this might give us something like "Unauthorized", which we can add to our error's clues later
    // (we'll exclude the range 500 - 599 as these are Server Errors which will be obvious where they apply):
    let statusText = err.statusText || actualError.statusText;
    statusText =
      statusText && statusText !== "Unknown" && (errStatus < 500 || errStatus > 599) ? statusText : undefined;

    // Historically, expected errors were identified with a leading and trailing %%.
    // For example, at the back-end, getPersonDetsForMobile() would return:
    //     %%Your device needs to be in the timezone of the organisation providing care for this customer%%
    // This approach is being phased-out (with status codes being used instead), but in case this version
    // of Mobile is used with a version of the back-end that still has these tags in play, we'll remove them:
    if (typeof message === "string" && message.startsWith("%%") && message.endsWith("%%")) {
      message = message.substring(2, message.length - 2);
    }

    this.debugBackend(`Error during http ${verb} (url: ${url}, status: ${errStatus}): ${message}`, DebugLvl.Error);

    // Now we have extracted the status, statusText and message, and done the necessary logging, we're
    // ready to decide if and how to re-throw the error.  ALL classes of error thrown below should be a
    // descendent of MobileError.
    if ([0, 200, 504].includes(errStatus)) {
      this.debugBackend(`We appear to have no network in a ${verb}`, DebugLvl.Heavy);
      if (noNetworkAction === NoNetworkAction.Null) {
        return null;
      } else {
        throw new NoNetworkError(errStatus);
      }
    } else if (errStatus === 500) {
      // 500 is the status used at the Plait backend for 'expected' errors (see server/lib/exceptions.ts ->
      // class ExpectedError) We will log these to Sentry if the error does not have the approvedForPOC flag.
      // This allows us to keep track of which expected errors are finding their way onto phones (where the
      // carer is helpless to do anything about them)
      if (!actualError.approvedForPOC) {
        Sentry.captureMessage(`${ERR_RECEIVED_EXPECTED_ERR}: ${message}`);
      }
      throw new ExpectedError(message);
    } else if (errStatus === 598) {
      // 598 is the status code used by the Plait backend for 'unexpected' errors that it doesn't want us to retry.
      // Before throwing the error we need to tidy up the data in some cases
      const match  = url.match(/apix\/event\/([0-9a-f]{24})\/([\d]+)\/actual(Start|Finish)/);
      if (match) {
        // Tried to figure out a better alternative to this, but failed.  And they deserve a bit of inconvenience anyway...
        this.observableEvents.publish("forciblyClearCache", true);
      }
      throw new NoRetryUnexpectedError(message, { url });
    } else {
      // Anything else is re-thrown as an UnexpectedHttpError.  We provide the status and statusText in this
      // case, so certain cases can receive special treatment when this error is caught.  For example, 599
      // is the status code used for any other type of error caught by a Plait apix endpoint.  The Plait
      // backend will have logged these to Sentry already, so code elsewhere will avoid doing so again.
      throw new UnexpectedHttpError(errStatus, statusText, message, { url, statusText });
    }
  }

  public async performHttpPostWithHeadersProvided(
    url: string,
    data: any,
    headers: ISimpleHeader,
    noNetworkAction: NoNetworkAction,
    params?: IHttpPostParams
  ): Promise<any> {
    this.debugBackend(`Post with headers provided ${url}`, DebugLvl.Heavy);
    // the only time when we would not expect headers to include Authorization
    // is when we are calling the authorization URL or setting up unauthenticated access
    if (!headers?.Authorization && url !== "auth/local" && url !== "auth/onsite-access") {
      if (!this.observableEvents.mockForTesting) {
        this.debugBackend(
          `Post with no authorization! url=${url}; data=${JSON.stringify(data, null, 2)}`,
          DebugLvl.Light
        );
      }
    }
    let fullUrl = `${DOMAIN}${url.slice(0, 1) === "/" ? "" : "/"}${url}`;
    if (params?.suppressErrorLogging) {
      // add suppressErrorLogging to fullUrl, making no assumptions about whether we already have a querystring
      const parsedUrl = new URL(fullUrl);
      parsedUrl.searchParams.set("suppressErrorLogging", "1");
      fullUrl = parsedUrl.toString();
    }
    try {
      this.debugBackend(`Posting ${fullUrl}`, DebugLvl.Heavy);
      const res = await this.http.post(fullUrl, data, { headers }).toPromise();
      if (res && (res as any)._body === "OK") {
        (res as any)._body = { OK: true }; // Special case where no object is returned
      }
      this.debugBackend(`Result of httpPost: ${JSON.stringify(res)}`, DebugLvl.Heavy);
      return res;
    } catch (err) {
      return this.handleHttpError(err, "POST", fullUrl, noNetworkAction);
    }
  }

  public async performHttpPost(
    url: string,
    data: any,
    noNetworkAction: NoNetworkAction,
    params?: IHttpPostParams
  ): Promise<any> {
    this.debugBackend(
      `performHttpPost for URL ${url}
with data: ${JSON.stringify(data)}`,
      DebugLvl.Light
    );
    const headers = await this._getHeaders();
    if (headers) {
      this.debugBackend(`auth: ${headers.Authorization}`, DebugLvl.Heavy);
      return await this.performHttpPostWithHeadersProvided(url, data, headers, noNetworkAction, params);
    } else {
      return null;
    }
  }

  // if an HTTP request is made using the parameter { responseType: "text" }, any error thrown during that
  // request will be string-encoded.
  // revive it as an object so it can be receive the same treatment as for any other responseType.
  private reviveStringEncodedError(err: any): any {
    // I think this probably never gets hit, because err itself (which is is really the http response) will
    // always (?) be an object...
    if (typeof err === "string") {
      try {
        err = JSON.parse(err, jsondiffpatch.dateReviver);
      } catch {
        // do nothing if this fails... though hopefully it won't
      }
    }
    // ...but the value returned by the backend (err.error) probably will be string-encoded, so...
    if (typeof err.error === "string") {
      try {
        err.error = JSON.parse(err.error, jsondiffpatch.dateReviver);
      } catch {
        // do nothing if this fails... though hopefully it won't
      }
    }
    return err;
  }

  public async performHttpGet(url: string, skipAuth: boolean): Promise<any> {
    this.debugBackend(`performHttpGet for URL ${url}`, DebugLvl.Light);
    const headers = await this._getHeaders(!skipAuth);
    if (headers) {
      if (headers.Authorization) {
        this.debugBackend(`auth: ${headers.Authorization}`, DebugLvl.Heavy);
      } else {
        if (!this.observableEvents.mockForTesting) {
          this.debugBackend("Get with no authorization!", DebugLvl.Light);
        }
      }
      let responseStr: string;
      try {
        responseStr = await this.http.get(this.cacheBust(url, true), { headers, responseType: "text" }).toPromise();
      } catch (err) {
        // { responseType: "text" } may cause the error to be string-encoded as well, so we will try
        // doing this (it will just return us err, unmodified, if it is already an object)
        const errObj = this.reviveStringEncodedError(err);
        if (errObj.status === 401) {
          if (skipAuth) {
            return 0;
          } else {
            this.observableEvents.publish("auth:unauthorised");
            throw new LoggedOutError("unauthorised");
          }
        } else {
          return this.handleHttpError(errObj, "GET", url);
        }
      }
      if (responseStr) {
        if (this.networkStatus() === undefined) {
          // if network status has not been set then tell it we are online
          this.networkStatus(NetworkStatus.On);
        }
        // Try to fix issue where we see status NoAuth
        if (headers.Authorization && this.backendStatus() === Status.NoAuth) {
          this.backendStatus(Status.On);
        }
        // We requested a responseType of "text" so we can do this parse ourselves.  This enables us to
        // do the same as we do when retrieving data from the cache - reviving Dates as Dates rather than
        // ISOStrings.
        try {
          return JSON.parse(responseStr, jsondiffpatch.dateReviver);
        } catch (e) {
          // if the parse fails, log the actual responseStr to the console to help with Sentry analysis
          this.debugBackend(`received invalid JSON: ${responseStr}`, DebugLvl.Error);
          return this.handleHttpError(e, "GET", url);
        }
      }
    } else {
      return null;
    }
  }

  // Try to send one cached write to the backend, then publish a nudge
  // so that other cached writes are also processed.
  public async _nextFlush(forced = false) {
    if (environment.enableDebug && !environment.production && this.dontSendWrites) {
      return;
    }
    this.debugBackend(
      `nextFlush() was called with status ${Status[this.backendStatus()]} and forced ${forced}`,
      DebugLvl.Heavy
    );
    if (this.backendStatus() !== Status.On && !forced) {
      this.debugBackend(`nextFlush() returned without action because we're not online`, DebugLvl.Heavy);
      return;
    }
    const nextWrite = await this.writeCache.getNextUpdateForBackEnd();
    if (!nextWrite) {
      return this.debugBackend("Write cache is empty, or has nothing needing a retry YET", DebugLvl.Heavy);
    }
    this.debugBackend(`Will try to post cached update: ${JSON.stringify(nextWrite)}`, DebugLvl.Heavy);
    try {
      const res = await this.performHttpPost(
        nextWrite.url,
        nextWrite.data,
        // force it to throw the error so we can be assured that res will ONLY be the result of a successful POST
        NoNetworkAction.Throw,
        {
          // tell the server not to log the error to Sentry if this is a retry, since it will already have
          // been logged once, and there is no benefit in adding to that noise for the same request
          suppressErrorLogging: nextWrite.errorCount > 0,
        }
      );
      // Success! So we can remove the update from the queue.  Tell clearAnUpdate NOT to force a flush after
      // the write cache has changed, because we'll do that ourselves in just a moment.
      await this.writeCache.clearAnUpdate(nextWrite.key, true);
      // Turn backendStatus back to On if it was Off (and this was presumably a forced attempt)
      if (this.backendStatus() !== Status.On) {
        this.debugBackend(`Successful post - setting network status to On`, DebugLvl.Heavy);
        this.backendStatus(Status.On);
      }
      // Modify caches if this update requires it
      if (nextWrite.url === "/apix/event/adhoc") {
        this.observableEvents.publish("backend:refresh:" + nextWrite.url, res);
      }
      this.observableEvents.publish("backend:nudge");
    } catch (err) {
      let logErr: string;
      if (err instanceof MobileError) {
        logErr = err.baseMsg;
      } else {
        // shouldn't get here if the error came from performHttpPost (because it should catch all errors and
        // only rethrow a descendent of MobileError).  the error could come from clearAnUpdate(), however...
        Sentry.captureMessage(`_nextFlush caught an unexpected class of error: ${JSON.stringify(err)}`);
        logErr = err.message || JSON.stringify(err);
      }
      this.debugBackend(
        `Error ${err.status} writing to ${nextWrite.url}: ${logErr}`,
        err.status !== 401 || err.statusText !== "Unauthorized" ? DebugLvl.Light : DebugLvl.Heavy
      );
      if (err instanceof NoNetworkError) {
        // "No network" (including the 504 case, where they do have network, but the server is not
        // responding).  In this case, we don't want to call failAnUpdate, as this would increase the retry
        // count, and cause it to be discarded if 5 retries have been made.  **Throwing away a write
        // just because there is no path to the server is not a good idea!**
        // Instead, we do nothing, which means this write will be attempted again (after all other
        // cached writes have been given their chance).
      } else if (err instanceof ExpectedError || err instanceof NoRetryUnexpectedError) {
        // "expected" errors will surely NOT fix themselves, making repeat attempts wasteful.
        // NoRetryUnexpectedError are ones the server is explictly asking us NOT to retry.
        await this.writeCache.clearAnUpdate(nextWrite.key, false);
        this.observableEvents.publish("displayError", logErr);
      } else if (err instanceof UnexpectedHttpError && err.status === 401 && err.statusText === "Unauthorized") {
        // Seeing several reports of updates failing because users are unauthorised.  Can't find out why,
        // but hopefully this will mitigate...
        this.backendStatus(Status.NoAuth);
      } else {
        await this.writeCache.failAnUpdate(nextWrite.key, err, logErr);
      }
      if (this.backendStatus() === Status.On && err.status !== 0) {
        this.debugBackend("Setting status to On on POST error");
        this.observableEvents.publish("backend:nudge");
      }
    }
  }

  private async onOffline(): Promise<void> {
    this.debugBackend("We have gone offline");
    // Some possibility of a problem here if current status is Waiting,
    // it is set to Offline and then Online before the server responds.
    // This could result in an update being sent twice.
    if (this.backendStatus() !== Status.NoAuth) {
      this.backendStatus(Status.Off);
    }
    await this.connectStatus();
  }

  public async onOnline(): Promise<void> {
    this.debugBackend("We have gone online");
    const token = await this.ionicStorageService.getRaw<string>("token");
    if (token) {
      if (token.slice(0, 7) === "OFFLINE") {
        // If we have not authenticated then attempt to do so in the background
        this.observableEvents.publish("auth:onOnline", token.slice(7));
      } else {
        // If we are authenticated then give the back end a kick
        this.backendStatus(Status.On);
        this.observableEvents.publish("backend:nudge");
      }
    }
    await this.connectStatus();
  }

  public isOnline(): boolean {
    return this.networkStatus() === NetworkStatus.On;
  }

  public async connectStatus(preventForcedFlush = false) {
    let retVal: string;
    const oldStatus = this._bannerStatus;
    const online = this.isOnline();
    if (this.writeCache) {
      const writeCacheInfo = this.writeCache.getWriteCacheInfo();
      if (writeCacheInfo.queue.length > 0) {
        retVal = online ? "secondary" : "danger";
      } else {
        retVal = online ? "light" : "dark";
      }
      this.debugBackend(`Header colour = ${retVal}`, DebugLvl.Heavy);
      if (retVal === "secondary" && !preventForcedFlush) {
        const now = new Date().valueOf();
        if (!this.tryForce) {
          this.tryForce = now + 10 * ONE_SECOND;
        } else if (now >= this.tryForce) {
          // See if we are "offline authenticated"
          const token = await this.ionicStorageService.getRaw<string>("token");
          if (token?.slice(0, 7) === "OFFLINE") {
            // and if so try and properly authenticate
            this.debugBackend('We seem to be "offline authenticated"', DebugLvl.Heavy);
            this.observableEvents.publish("auth:onOnline", token.slice(7));
          } else {
            const diags = this.writeCache.getWriteCacheInfo();
            delete diags.queue;
            this.debugBackend(JSON.stringify(diags), DebugLvl.Heavy);
            await this._nextFlush(true);
          }
        }
      } else {
        this.tryForce = null;
      }
      this._bannerStatus = retVal;
      if (oldStatus !== this._bannerStatus && !this.observableEvents.mockForTesting) {
        this.observableEvents.publish("connectivity", this._bannerStatus);
      }
    }
  }

  public bannerStatus(): string {
    return this._bannerStatus;
  }
}
