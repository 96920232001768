import { Injectable } from "@angular/core";

import { SingletonProvider } from "../../lib/singletonProvider";
import { IonicStorageService } from "../../services/ionicStorageService";
import { RCErrorHandler } from "../../error.handler";

interface ICurrentRelease {
  sha?: string;
}

@Injectable({
  providedIn: "root",
})
export class ReleaseProvider extends SingletonProvider<ICurrentRelease> {
  constructor(ionicStorage: IonicStorageService, errorHandler: RCErrorHandler) {
    super(ionicStorage, errorHandler);
  }

  public get storageKey() {
    return "release";
  }

  protected getDefault(): ICurrentRelease {
    return {};
  }

  public getCurrentRelease(): ICurrentRelease {
    return this.data;
  }
}
