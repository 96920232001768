import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/capacitor";
import { ONE_MINUTE, ONE_SECOND } from "../constants";
import { LogLvl } from "../lib/logger";
import { SingletonProvider } from "../lib/singletonProvider";
import { ObservableEventsService } from "./observableEventsService";
import { IonicStorageService } from "./ionicStorageService";
import { RCErrorHandler } from "../error.handler";

const MAX_ERRORS = 5;

export interface CachedPost {
  url: string;
  data: any;
  postTime: number; // Date value
  inFlightSince?: number; // Date value
  lastError?: string;
  lastErrorStatus?: number;
  errorCount?: number;
  nextRetry?: number; // Date value
}

export interface KeyedCachedPost extends CachedPost {
  key: string; // uuid;
}

interface WriteCacheInfo {
  untried: number;
  retries: number;
  inFlight: number;
  oldestUntried?: KeyedCachedPost; // key of KeyedCachedPost
  nextRetry?: KeyedCachedPost;
  queue: KeyedCachedPost[];
}

@Injectable({
  providedIn: "root",
})
export class WriteCacheService extends SingletonProvider<KeyedCachedPost[]> {
  constructor(
    ionicStorage: IonicStorageService,
    errorHandler: RCErrorHandler,
    private observableEvents: ObservableEventsService,
  ) {
    super(ionicStorage, errorHandler);
  }

  public get storageKey() {
    return "writes";
  }

  // This shouldn't be used - it has been added only for use by tests
  public async removeAll() {
    await super.removeAll();
  }

  protected getDefault(): KeyedCachedPost[] {
    return [];
  }

  public getAllItems(): string {
    if (this.data.length === 0) {
      return "The cache is empty";
    }
    let result = "<table><tr><th>Url</th><th>Retries</th><th>Next Retry</th><th>Last Error</th></tr>";
    const allItems = [...this.data];
    allItems.sort((a, b) => a.errorCount - b.errorCount);
    result += allItems
      .map((i) => {
        const errorCount = i.errorCount?.toString() || "none";
        const lastError = i.lastError || "";
        let nextRetry = "";
        if (i.nextRetry) {
          const dt = new Date(i.nextRetry);
          nextRetry = `${dt.getHours().toString().padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}`;
        }
        return `<tr><td>${i.url}</td><td>${errorCount}</td><td>${nextRetry}</td><td>${lastError}</td></tr>`;
      })
      .join("");
    result += "</table>";
    return result;
  }

  public getWriteCacheInfo(): WriteCacheInfo {
    // returns +ve number if there are untried posts, and -ve number if there are only retries, 0 otherwise
    let untried = 0;
    let retries = 0;
    let inFlight = 0;
    const now = new Date().valueOf();
    const stallThreshold = now - ONE_MINUTE;
    let oldestUntried: KeyedCachedPost;
    let nextRetry: KeyedCachedPost;
    for (const post of this.data) {
      if (post.inFlightSince && post.inFlightSince.valueOf() < stallThreshold) {
        delete post.inFlightSince; // Don't count this as an error - just a network glitch
      }
      if (post.inFlightSince) {
        inFlight += 1;
      } else {
        if (post.errorCount) {
          retries += 1;
          if (
            post.nextRetry < now &&
            (!nextRetry ||
              post.errorCount < nextRetry.errorCount ||
              (post.errorCount === nextRetry.errorCount && post.nextRetry < nextRetry.nextRetry))
          ) {
            nextRetry = post;
          }
        } else {
          untried += 1;
          if (!oldestUntried || post.postTime < oldestUntried.postTime) {
            oldestUntried = post;
          }
        }
      }
    }
    return {
      untried,
      retries,
      inFlight,
      queue: this.data,
      oldestUntried,
      nextRetry,
    };
  }

  public async getNextUpdateForBackEnd(): Promise<KeyedCachedPost | null> {
    const writeCacheInfo = this.getWriteCacheInfo();
    this.logger.log("writeCacheInfo: " + JSON.stringify(writeCacheInfo), LogLvl.Heavy);
    const retVal = writeCacheInfo.oldestUntried || writeCacheInfo.nextRetry;
    // if (new Date() < new Date(2021,1,7, 23)) {
    //   // for testing.  We don't want to write anything, so we can see if our updates get overwritten by a cache read.
    //   retVal = undefined;
    // }
    if (retVal) {
      this.logger.log("will post: " + JSON.stringify(retVal), LogLvl.Heavy);
      retVal.inFlightSince = new Date().valueOf();
      await this.updateStorage();
    }
    return retVal;
  }

  public async clearAnUpdate(key: string, preventForcedFlush: boolean): Promise<void> {
    this.data = this.data.filter((q) => q.key !== key);
    this.logger.log(`Clearing update ${key}.  Left with:\n${JSON.stringify(this.data)}`, LogLvl.Light);
    await this.updateStorage();
    this.observableEvents.publish("updateCacheStatus");
    this.observableEvents.publish("writeCacheChange", { preventForcedFlush });
  }

  public async clearAnUpdateByUrl(urlSuffix: string, onlyIfAttempted: boolean): Promise<void> {
    if (!this.data.some((d) => d.url.endsWith(urlSuffix) && (!onlyIfAttempted || d.errorCount > 0))) {
      return;
    }
    this.data = this.data.filter((d) => !(d.url.endsWith(urlSuffix) && (!onlyIfAttempted || d.errorCount > 0)));
    this.logger.log(`Clearing update by url ${urlSuffix}.  Left with:\n${JSON.stringify(this.data)}`, LogLvl.Heavy);
    await this.updateStorage();
    this.observableEvents.publish("updateCacheStatus");
    this.observableEvents.publish("writeCacheChange");
  }

  private uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      // eslint-disable-next-line no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      // eslint-disable-next-line no-bitwise
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public async cacheAnUpdate(url: string, data: any, replaceExistingItem: boolean): Promise<void> {
    const newPost: KeyedCachedPost = {
      url,
      data,
      postTime: new Date().valueOf(),
      key: this.uuidv4(),
    };
    if (replaceExistingItem) {
      const existingIdx = this.data.findIndex((d) => d.url === url);
      if (existingIdx > -1) {
        this.data[existingIdx] = newPost;
      } else {
        this.data.push(newPost);
      }
    } else {
      this.data.push(newPost);
    }
    await this.updateStorage();
    this.logger.log(`Cached update: ${JSON.stringify(newPost)}`, LogLvl.Heavy);
    this.observableEvents.publish("updateCacheStatus");
    this.observableEvents.publish("writeCacheChange");
  }

  public async failAnUpdate(key: string, err: any, logErr: string): Promise<boolean> {
    this.logger.log(`>>>> Handling error ${err.message || err} - ${logErr}`, LogLvl.Heavy);
    let result = false;
    const storedPostPos = this.data.findIndex((q) => q.key === key);
    if (storedPostPos !== -1) {
      const storedPost = this.data[storedPostPos];
      delete storedPost.inFlightSince;
      storedPost.lastError = logErr;
      storedPost.lastErrorStatus = err.status;
      storedPost.errorCount = (storedPost.errorCount || 0) + 1;
      let retryInMsFromNow: number;
      if (this.observableEvents) {
        // This is not a test
        retryInMsFromNow = ONE_SECOND * Math.pow(2, 9 - (MAX_ERRORS - storedPost.errorCount));
      } else {
        // don't hand around if testing
        retryInMsFromNow = 0;
      }
      storedPost.nextRetry = new Date().valueOf() + retryInMsFromNow;
      if (this.observableEvents) {
        // setting nextRetry won't actually cause a retry to happen, and we intentionally don't have a heartbeat
        // continuously checking for retries needing to be made.  to force a retry, we need to publish
        // "backend:nudge" sometime after retryInMsFromNow.  We'll do it 3 seconds afterwards, hopefully giving
        // plenty of leeway for setTimeout not to invoke too early.
        retryInMsFromNow += ONE_SECOND * 3;
        setTimeout(() => {
          this.logger.log(`Retry timeout fired at ${new Date()}`, LogLvl.Error);
          this.observableEvents.publish("backend:nudge");
        }, retryInMsFromNow);
      }
      let error: string;
      if (logErr && logErr.replace) {
        error = logErr
          .replace(/Http failure response for http(s?):\/\/(www.plaitapp.org|localhost:3000)/, "")
          .replace(storedPost.url, "")
          .replace(/^: /, "");
      } else {
        try {
          error = `Unavailable: ${typeof logErr}`;
        } catch (e) {
          error = `${e.message} thrown`;
        }
      }
      const details = [`Status: ${err.status}${err.statusText ? " " + err.statusText : ""}`, `Error: ${error}`];
      // we have historically included this information, but I think this can only be useful when debugging the retry logic.  for
      // live operation, all this serves to do is reduce the chance of Sentry grouping repeated examples of the same error
      //details.push(`Error count: ${storedPost.errorCount + 1}`);
      //details.push(`Next Retry: ${new Date(storedPost.nextRetry)}`);

      // for the sentryLog, we don't want to include URL or Data because these will also prevent repeated examples of the
      // same error from being grouped.  BUT... to help with investigations, we should still log these to the console, which we
      // should do first to ensure that it does show in the breadcrumbs.
      const sentryLog = storedPost.errorCount >= MAX_ERRORS ? details.join("; ") : undefined;
      details.push(`URL: ${storedPost.url}`);
      details.push(`Data: ${JSON.stringify(storedPost.data, null, 2)}`);
      this.logger.log(details.join("; "), LogLvl.Error);
      if (sentryLog) {
        Sentry.captureMessage(`Max retries failed.  ${sentryLog}`);
        this.data.splice(storedPostPos, 1);
        result = true;
      }
      await this.updateStorage();
    }
    return result;
  }
}
