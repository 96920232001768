import { Injectable } from "@angular/core";

import { BaseObjectStorage } from "./baseStorage";
import { IonicStorageService } from "./../services/ionicStorageService";

@Injectable({
  providedIn: "root",
})
export class IonicStorage<T> extends BaseObjectStorage<T> {
  constructor(private ionic: IonicStorageService) {
    super();
  }

  public setRaw(raw: T, itemKey: string): Promise<void> {
    return this.ionic.setRaw(raw, itemKey);
  }

  public getRaw(itemKey: string): Promise<T> {
    return this.ionic.getRaw<T>(itemKey);
  }

  public getAllKeys(): Promise<string[]> {
    return this.ionic.getAllKeys();
  }

  public removeItem(itemKey: string): Promise<void> {
    return this.ionic.removeItem(itemKey);
  }

  // Use with extreme caution!!  All instances of this class will be affected.
  public removeAll(): Promise<void> {
    return this.ionic.removeAll();
  }
}
