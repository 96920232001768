import { Injectable } from "@angular/core";

import * as constants from "../../constants";

import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ICareplanStructure } from "../../lib/careplan";
import { BackendProvider, Collection, URLType } from "../../lib/backendProvider";
import { ObservableEventsService } from "../../services/observableEventsService";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { BackendService } from "../../services/backend.service";
import { RCErrorHandler } from "../../error.handler";

export interface NotYetCachedCareplanStructure extends ICareplanStructure {
  _id: string;
}

@Injectable({
  providedIn: "root",
})
export class CareplanStructureProviderService extends BackendProvider<ICareplanStructure> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<ICareplanStructure>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.CareplanStructure,
      Collection.CareplanStructure,
      constants.ONE_WEEK * 208, // keep data in the cache for a maximum of 4 years
      constants.ONE_WEEK * 208 // and never refresh it
    );
  }

  public async getCareplanStructure(id: string): Promise<ICareplanStructure> {
    // For now, work on the basis that careplan structures never change.  This isn't strictly true, however -
    // needs further thought.
    const result = await super.getImmutable(id, URLType.Extended);
    return result.data;
  }

  public async preloadCache(structures: NotYetCachedCareplanStructure[]): Promise<void> {
    for (const structure of structures) {
      const cacheKey = this.getCacheKey(structure._id);
      delete structure._id;
      await this.readCache.store(structure, cacheKey);
    }
  }

  public async getAllCachedStructureIds(): Promise<string[]> {
    const prefix = this.cacheKeyPrefix;
    const keys = await this.readCache.getAllCacheKeys(prefix);
    return keys.map((k) => k.substring(prefix.length + 1));
  }

  public removeItem(structureId: string): Promise<void> {
    const cacheKey = this.getCacheKey(structureId);
    return this.readCache.remove(cacheKey);
  }
}
