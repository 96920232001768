import { ObservableEventsService } from "../services/observableEventsService";
import { ReadCacheService, CacheData, DataFromCache, KeyedCacheData } from "../services/readCacheService";
import { Logger, LogLvl } from "./logger";

export enum CacheKeyPrefix {
  None = "",
  TagOwner = "pt",
  PersonLookup = "pl",
  PhoneOwner = "pd",
  CarerEvents = "ce",
  EventWindow = "ew",
  HistoricEventLog = "hel",
  EventType = "et",
  Run = "r",
  ParentEvent = "pe",
  ParentMed = "pm",
  ParentTask = "ptk",
  CurrentClientType = "cc",
  ClientSessions = "cs",
  Medicine = "m",
  CareplanStructure = "cps",
  Careplan = "cp",
  Test = "x",
}

/**
 * A base class for all providers that cache data using offline storage
 */
export abstract class ProviderWithCaching<T> {
  protected cacheAgeLimit: number;
  protected cacheRefreshAfter: number;
  protected logger: Logger;

  constructor(
    protected readCache: ReadCacheService<T>,
    protected observableEvents: ObservableEventsService,
    public cacheKeyPrefix: CacheKeyPrefix
  ) {
    this.logger = new Logger();
  }

  protected getCacheKey(id: string): string {
    return `${this.cacheKeyPrefix}.${id}`;
  }

  protected store(obj: T, id: string): Promise<CacheData<T>> {
    const cacheKey = this.getCacheKey(id);
    return this.readCache.store(obj, cacheKey);
  }

  public async getFromId(id: string, throwOnCacheMiss: boolean): Promise<T> {
    const cacheKey = this.getCacheKey(id);
    const cached = await this.getCached(cacheKey);
    if (!cached) {
      if (throwOnCacheMiss) {
        const allKeys = await this.readCache.getAllCacheKeys(this.cacheKeyPrefix);
        const errorMsg = "No cached record with key " + cacheKey;
        this.logger.log(
          `${errorMsg}.  The cache currently contains ${allKeys.length} item(s) whose key begins with ${this.cacheKeyPrefix}.  ` +
            `They are: ${allKeys.join(",")}`,
          LogLvl.Error
        );
        throw new Error(errorMsg);
      } else {
        return;
      }
    }
    return cached.obj;
  }

  protected async getCached(cacheKey: string): Promise<DataFromCache<T>> {
    this.logger.log(`getCached().  Checking cache against key: ${cacheKey}`, LogLvl.Heavy);
    const cachedValue = await this.readCache.get(cacheKey, this.cacheAgeLimit, this.cacheRefreshAfter);
    if (cachedValue) {
      this.logger.log(`Record found!  Data: ${this.formatDataForLogging(cachedValue.obj)}`, LogLvl.Heavy);
    }
    return cachedValue;
  }

  protected async getAllCached(): Promise<KeyedCacheData<T>[]> {
    this.logger.log(`getAllCached().  Retrieving all items with key prefix: ${this.cacheKeyPrefix}`, LogLvl.Heavy);
    const cachedValues = await this.readCache.getAll(this.cacheKeyPrefix);
    if (cachedValues) {
      const n = cachedValues.length;
      this.logger.log(`${n} record(s) found!  Data: ${this.formatDataForLogging(cachedValues)}`, LogLvl.Heavy);
    }
    return cachedValues;
  }

  protected formatDataForLogging(data: any): string {
    const describingProperties = ["familyName", "name", "description", "text", "_id"];
    let description: string;
    if (data === null) {
      description = "null";
    } else if (data === undefined) {
      description = "undefined";
    }
    if (!description && Array.isArray(data)) {
      if (data.length > 0) {
        const firstItem = data[0];
        if (firstItem.clients || firstItem.carers) {
          description = "event ";
        } else if (firstItem.shortDesc) {
          description = "med session ";
        }
      }
      description = `Array of ${data.length} ${description || ""}record(s)`;
    }
    if (!description && data.availability) {
      const parts: string[] = [];
      for (const prop of ["events", "availability", "runAllocations"]) {
        if (data[prop]) {
          parts.push(`${prop}: ${data[prop].length} records`);
        }
      }
      for (const prop of ["runs", "people", "eventTypes"]) {
        if (data[prop]) {
          parts.push(`${prop}: ${Object.keys(data[prop]).length} records`);
        }
      }
      description = "Expressed Carer - " + parts.join(", ");
    }
    if (!description) {
      for (const prop of describingProperties) {
        description = data[prop];
        if (description) {
          break;
        }
      }
    }
    if (!description) {
      description = JSON.stringify(data, null, 2).substring(0, 100);
    }
    return description;
  }

  public getAllCacheKeys(prefix?: string) {
    return this.readCache.getAllCacheKeys(prefix);
  }

  public static getRecordIdsFromCacheKeys(allKeys: string[], cacheKeyPrefix: CacheKeyPrefix): string[] {
    const prefix = cacheKeyPrefix + ".";
    return allKeys.filter((key) => key.startsWith(prefix)).map((key) => key.replace(prefix, ""));
  }
}
