import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler as AngularErrorHandler, Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { captureException } from "@sentry/browser";
import { addExceptionMechanism } from "@sentry/utils";

import * as Sentry from "@sentry/browser";

import { ObservableEventsService } from "./services/observableEventsService";
import { BETA, environment } from "../environments/environment";

// Taken from https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/zone.ts
declare const Zone: any;
const isNgZoneEnabled = typeof Zone !== "undefined" && !!Zone.current;
function runOutsideAngular<T>(callback: () => T): T {
  return isNgZoneEnabled ? Zone.root.run(callback) : callback();
}

export const ERR_REFRESH_CACHE = "Try refreshing your cache.";
export const ERR_REPORT_TO_OFFICE = "If this problem continues to occur, please contact your office for assistance.";
export const ERR_BROWSER_RESTART_REQD =
  "You will probably need to close the browser and restart before you will be prompted to do that.";
export const ERR_LOGGED_OUT_SIMPLE = "You will be logged out.";
export const ERR_LOGGED_OUT_MEANTIME = "In the meantime, you will be logged out.";
export const ERR_LOGGED_OUT_AND_PRAY = "You will be logged out and hopefully things will be OK when you start again.";
export const ERR_SUPPORT_INFORMED = "The support team has been informed"; // don't put a . at the end of this one, as it's sometimes used as part of a longer sentance 
export const ERR_RECEIVED_EXPECTED_ERR = "Received Expected Error from backend";

export const ERR_THIS_IS_CACHED_DATA = "You are being shown cached data.  Please refresh when you can.";
export const ERR_THE_ERR_WAS = "The error was:";
export const ERR_OCCURRED_WHILST = "An error occurred whilst";

const CAMERA_PERMISSION_ERR_PARTS = [
  "NotAllowedError: ",
  "No video devices found for scanning QRCode",
  "The request is not allowed by the user agent or the platform in the current context",
];
const GEOLOC_ERR_PARTS = ["GeolocationPositionError", "Cannot read properties of undefined (reading 'longitude')"];
const STORAGE_FULL_ERR_PARTS = ["The quota has been exceeded"];
const IGNORE_ERR_PARTS = [
  // This shouldn't need to be here, because as well as checking IGNORE_ERR_PARTS, we also test for the error being an
  // instance of SilentError (which the LoggedOutError is).  However, we have seen "not logged in" in Sentry on very
  // rare occasions, so let's include this here as well as an additional guard against that happening again.
  "not logged in",
  // This error is occasionally seen when an ion-icon's svg image fails to load.
  // See for example https://reallycare.sentry.io/issues/3710421091/events/9c7e2bf7130e4546b37f08710779375e/
  // It appears to be an occasional problem affecting Apple devices only.  Couldn't find any definitive solution, and as it is
  // a) costmetic b) hidden away in the local menu and c) temporary, we will simply swallow these errors.  Another
  // possible option would be to force a reload (as we do for ChunkLoadError) but that seems a bit drastic for
  // something that isn't preventing the app from working.
  "Load failed",
  // This error is seen a lot in Sentry.  According to the following page, it occurs when attempting to open a menu
  // while a (swipe?) animation is in-progress:
  // https://github.com/ionic-team/ionic-framework/issues/19000
  // According to that same link, it's fixed now, but as we haven't yet updated to a version of Ionic that includes the
  // fix (and we might not be doing so for some time), I'm putting this here in the hope that the errors (which are
  // harmless) can be ignored.
  "can not be animating",
];
// Some 'exptected' errors should cause the user to be logged-out.
const EXPECTED_ERRS_REQ_LOGOUT = ["needs to be in the timezone"];

// see https://forum.ionicframework.com/t/chunkloaderror-and-o-isproxied-error/209365/6
// or https://medium.com/@kamrankhatti/angular-lazy-routes-loading-chunk-failed-42b16c22a377
const LOADING_ERR_PARTS = ["isProxied", "ChunkLoadError", "Loading chunk"];

// All errors thrown in this project should use a subclass of MobileError.
// The clues object is provided to encapsulate the common requirement for additional information to
// be appended to the end of an error message to provide further clues as to its cause.
// If the error is being logged to Sentry, the error log will definitely include these clues, by virtue of them
// being appended to the end of the Error's message by the message() getter in this class.
// If the error is being shown on-screen, the caller can use the message property if they DO wish to include
// the clues, or the baseMsg property if they do not.
export abstract class MobileError extends Error {
  constructor(public status: number, public baseMsg: string, public clues?: object) {
    super();
  }

  // very rudimentary - there's surely a more elegant way to do this, but it'll do for now
  get message(): string {
    let result = this.baseMsg;
    if (this.clues) {
      let cluesStr = "";
      const keys = Object.keys(this.clues);
      for (const key of keys) {
        let value = this.clues[key];
        if (value === undefined) {
          continue;
        }
        if (cluesStr) {
          cluesStr += ", ";
        }
        if (value === null) {
          value = "null";
        } else if (value instanceof Date) {
          value = value.toISOString();
        } else if (value instanceof Object) {
          value = JSON.stringify(value);
        } else if (value.toString) {
          value = value.toString();
        }
        cluesStr += `${key}: ${value}`;
      }
      if (cluesStr) {
        result += `<br>(${cluesStr})`;
      }
    }
    return result;
  }
}

// Instances of BaseExpectedError will be shown to the user with the actual message (rather than a generic,
// "Something went wrong").
// When an expected error is received from the backend (indicated by a status code of 500), the error will be
// logged to Sentry unless the server passes the approvedForPOC flag.  This is so we can monitor which expected
// errors originating from the backend can make it onto carer's phones (where they will probably be helpless to
// do anything about them).
// Otherwise, the error will not go to Sentry at all.
// Although it is not expected to be used anywhere in this project (checking for "error instanceof BaseExpectedError"
// would be preferable), we will mirror the status of 500 used by the ExpectedError class at the backend.  This could
// help to quickly distinguish between expected and unexpected errors when the status is included in debug logs.
// Useful for things like: data missing from the cache
export class ExpectedError extends MobileError {
  constructor(message: string, messageClues: object = undefined) {
    super(500, message, messageClues);
  }
}

export class FatalCameraError extends ExpectedError {}

// Instances of BaseUnexpectedError will force the user to be logged out, with a generic "Something went wrong" message.
export abstract class BaseUnexpectedError extends MobileError {}

// BaseUnexpectedError is abstract.  Concrete instances should choose between the following subclasses:
// 1. For errors raised in Plait POC code that do not relate to a server request.
export class UnexpectedLocalError extends BaseUnexpectedError {
  constructor(message: string, sentryClues: object = undefined) {
    // 301 has no particular meaning
    super(301, message, sentryClues);
  }
}
// 2. For errors thrown by an http request that have a status code other than 500 or 598.  This means it can include not only
// those with a status of 599 (used by the server's own UnexpectedError class), but also any other error caught while making
// an http request, such as "unauthorized" (401).  As such, the status code could be anything in the 400 - 599 range.
// 599 errors will NOT be logged to Sentry, on the basis that this will already have been done at the backend.
export class UnexpectedHttpError extends BaseUnexpectedError {
  constructor(status: number, public statusText: string, message: string, sentryClues: object = undefined) {
    super(status, message, sentryClues);
  }
}

// Instances of NoRetryUnexpectedError are only generated if the response to a write request has a status of 598.
// This is the way that the Plait Backend tells us that the failure was unexpected, but we should NOT try again.
// See the class of the same name in server/lib/exceptions.ts.
// Although this should not be used anywhere in this project (it is far better to check for "error instanceof
// NoRetryUnexpectedError"), we will mirror the status used by the backend so err.status can be included in debug logs.
export class NoRetryUnexpectedError extends MobileError {
  constructor(message: string, sentryClues: object) {
    super(598, message, sentryClues);
  }
}

// "Silent" errors do not get logged to Sentry or display any message - they allow the thrower to take responsibility
// for any necessary logging / communication, while clearing out the call stack.
// Equivalent of a Delphi Abort.
export class SilentError extends MobileError {
  // The 901 status used here has no special meaning and is not expected to be used.
  constructor(message?: string) {
    super(901, message);
  }
}

// LoggedOutError is a particular example of a SilentError, for use when something is happening that cannot happen,
// either because either the user is logged out, or is in the process of being logged out (as a result of failed
// authorisation during an HTTP request).
export class LoggedOutError extends SilentError {}

// NoNetworkError is thrown when handleHttpError is passed an error with the status 0 or 200, and its noNetworkAction
// argument is "throw".  (Although 200 usually identifies success in $http(...).then(), it also identifies a failed
// dispatch in $http(...).catch(), which is most probably caused by a network problem).
export class NoNetworkError extends MobileError {
  constructor(status: 0 | 200) {
    super(status, "No network");
  }
}

interface ErrorHandlerOptions {
  logErrorsToConsole?: boolean;
  logErrorsToSentry?: boolean;
  showSentryReportDialog?: boolean;
  dialogOptions?: Sentry.ReportDialogOptions;
}

interface IErrorAction {
  do: boolean;
}

interface IDisplayAction extends IErrorAction {
  lines?: string[];
  sanitised?: boolean;
}

interface ILogoutAction extends IErrorAction {
  explain?: boolean;
}

interface ISentryAction extends IErrorAction {
  notifySupportInformed?: boolean;
}

interface IErrorAnalysis {
  actualError: MobileError;
  errorMessage: string;
  display: IDisplayAction;
  logout: ILogoutAction;
  sentry: ISentryAction;
  // for now, the reload option only works properly if logout.do and display.do are also true
  // thorough testing will be required if any other combination is required
  reload: IErrorAction;
}

interface IErrorActionOverrides {
  noLogout?: boolean;
  noDisplay?: boolean;
  noSentry?: boolean;
  noReload?: boolean;
}

/**
 * Implementation of Angular's ErrorHandler provider that can be used as a drop-in replacement for the stock one.
 */
@Injectable({ providedIn: "root" })
export class RCErrorHandler implements AngularErrorHandler {
  private _options: ErrorHandlerOptions;

  // keep track of whether a message box is pending in response to a caught error, and if it is, the Sentry event Id (if any)
  // which relates to that error, and whether or not a reload is required after the user has acknowledged the error.
  // further errors arising before this flag is reset will NOT trigger an additional message box (since we don't want to give
  // the use a stack of messages to acknowledge), and if this._options.showDialog is true, we'll not display the Sentry dialog
  // until after the user has acknowledged the pending message (because otherwise, they won't know what error they are
  // being asked to describe the circumstances of).
  private pendingInterractions = { messageBox: false, sentryEventId: "", reload: false };

  public constructor(private alertCtrl: AlertController, private observableEvents: ObservableEventsService) {
    this._options = {
      logErrorsToConsole: true,
      logErrorsToSentry: !environment.suppressSentry,
      showSentryReportDialog: !environment.suppressSentry && environment.enableDebug,
    };
  }

  private isLoggedIn(): boolean {
    return document?.location?.pathname?.startsWith("/login");
  }

  /**
   * This is our "global" handler for unhandled exceptions - when an exception is thrown and not subsequently caught,
   * it will end up here.  Depending upon the type of error caught, we might perform all, some or none of the following actions:
   *   - display a message to the user
   *   - log the error to Sentry
   *   - force the user to be logged out
   *   - reload the page
   */
  public async handleError(error: unknown): Promise<void> {
    const analysis = this.analyseError(error, {});
    await this.handleMessageDisplay(analysis);
    this.handleSentryLogging(analysis, false);
    this.handleForcedLogout(analysis);
    this.handleReload(analysis);
  }

  /**
   * For caught exceptions, where the catcher wants to take responsibility for everything except Sentry logging.
   * Returns an IDisplayAction instance, giving the caller the information it needs to display a message to the user
   * where appropriate.
   */
  public handleSentryOnly(error: unknown, breadcrumbs?: string[]): IDisplayAction {
    const analysis = this.analyseError(error, { noDisplay: true, noLogout: true, noReload: true });
    // seeing some Sentry events that don't contain enough information to diagnose, but I think they may be coming
    // through this route.  do some additional logging to hopefully prove this - could perhaps remove this in 2024
    if (breadcrumbs) {
      // eslint-disable-next-line no-console
      console.log(`handleSentryOnly called with "${analysis.errorMessage}" from ${breadcrumbs.join(" => ")}`);
    }
    this.handleSentryLogging(analysis, false);
    return analysis.display;
  }

  /**
   * All Promises where specific error-handling logic has not yet been written should include a catch that passes
   * the caught error here.  For errors thrown by our own code, we will rethrow the error without modification.  Anything
   * else will be rethrow as an UnexpectedLocalError, with the given breadcrumbs added to its clues, to help with understanding
   * the context when the error is seen in Sentry.  This will be caught by the global handler (handleError(), above)
   * and result in a "Something went wrong" message, a log to Sentry, and the user being forcibly logged-out.  This is
   * the same as if we'd not caught the error at all, except we are able to add breadcrumbs to help the Sentry analysis,
   * with the goal of better-handling (or avoiding entirely) each observed example.  And... we can avoid having floating
   * promises.
   */
  public handleUnexpected(error: unknown, breadcrumbs: string[]): void {
    if (error instanceof MobileError) {
      throw error;
    }
    const analysis = this.analyseError(error, {});
    const clues = analysis.actualError.clues || {};
    if (breadcrumbs?.length > 0) {
      clues["breadcrumbs"] = breadcrumbs;
    }
    throw new UnexpectedLocalError(analysis.errorMessage, clues);
  }

  /**
   * Analyse the given error, identifying its category, which of the standard error-handing actions should apply to
   * it, and the most suitable message to describe it.
   */
  private analyseError(error: unknown, overrides: IErrorActionOverrides): IErrorAnalysis {
    const { actualError, message } = this.extractError(error);
    const analysis: IErrorAnalysis = {
      errorMessage: message,
      actualError,
      // just some defaults that minimise the number of lines of code needed to set the correct values, below, according
      // to the type of error we have found...:
      display: {
        do: true,
      },
      logout: {
        do: true,
        explain: true,
      },
      sentry: {
        do: false,
      },
      reload: {
        do: false,
      },
    };
    if (actualError instanceof SilentError || this.includesAnyOf(message, IGNORE_ERR_PARTS)) {
      // Nothing for us to do.
      analysis.logout.do = false;
      analysis.display.do = false;
    } else if (actualError instanceof NoNetworkError) {
      // Note that we don't get here for failed writes, only for any other GETs and POSTs that use NoNetworkAction.Throw.
      // This should only be user-initiated requests that require immediate network coverage (i.e., without a cache
      // backup, or some kind of retry loop).  For example, a Bookings Listing request.
      analysis.logout.do = false;
      analysis.display.lines = ["You appear to have no network.  Try again while in signal, or connect to Wifi."];
    } else if (actualError instanceof ExpectedError) {
      // for "expected" errors (which could have originated locally, or been thrown following a 500 status response to a
      // server request), we will show the actual message to the user, as this is supposed to be (at least somewhat) meaningful.
      // Instances originating from the back-end might have a less meaningful message than we would like, but
      // examples of this are being monitored (see ERR_RECEIVED_EXPECTED_ERR), and the messages should be reviewed as part
      // of that process to make sure they are suitable for POC users.
      analysis.display.lines = [message];
      if (this.includesAnyOf(message, EXPECTED_ERRS_REQ_LOGOUT)) {
        analysis.logout.explain = false;
        analysis.display.lines.push(ERR_LOGGED_OUT_SIMPLE);
      } else {
        analysis.logout.do = false;
      }
    } else if (this.includesAnyOf(message, CAMERA_PERMISSION_ERR_PARTS)) {
      // TODO: Investigate how to do this properly
      analysis.display.lines = [
        "You must grant permission for the camera to scan the QRCode.",
        "For assistance see section 5 of the 'Troubleshooting the Plait Point of Care Progressive Web App' at http://support.reallycare.org."
      ];
      // Because scanning often happens from the login page, we need to check that they are actually logged in before telling
      // them that we will be logging them out!!
      if (!this.isLoggedIn()) {
        analysis.display.lines.push(ERR_LOGGED_OUT_MEANTIME);
      }
      analysis.logout.explain = false;
    } else if (actualError instanceof FatalCameraError) {
      analysis.logout.do = false;
      analysis.display.lines = [
        "Something went wrong while attempting the scan.",
        "Please try again.  If this happens again, you may need to restart.",
        `The error reported by your device was: ${message}`,
      ];
    } else if (this.includesAnyOf(message, GEOLOC_ERR_PARTS)) {
      // TODO: Investigate how to do this properly
      analysis.display.lines = [
        "You must grant geolocation permission.",
        ERR_BROWSER_RESTART_REQD,
        ERR_LOGGED_OUT_MEANTIME,
      ];
      analysis.logout.explain = false;
    } else if (this.includesAnyOf(message, STORAGE_FULL_ERR_PARTS)) {
      analysis.display.lines = [
        "Failed to cache data for offline access because your browser storage is full.",
        "You can try clearing your cache, but if this continues to occur, you will either need to adjust browser storage settings, or ask your office to reduce the size of the cached data window.",
      ];
      analysis.logout.explain = false;
    } else if (this.includesAnyOf(message, LOADING_ERR_PARTS)) {
      // these errors can occur when cached (lazily-loaded) pages 'clash' with the new code when a new version is deployed.
      // according to the links given in the comment alongside LOADING_ERR_PARTS, the problem goes away if a reload is forced.
      analysis.reload.do = true;
      analysis.logout.do = false;
      analysis.display.do = false;
    } else {
      // anything else is "unexpected" - whether it's an instance of BaseUnexpectedError or not.
      // where it *is* an instance of BaseUnexpectedError, this could have originated locally, or been thrown following a
      // 599 status response to a server request.  the latter does not need to be logged to Sentry by us, becase this will
      // have already happened at the backend.
      // The user should be logged out, as - depending upon where this was thrown - the system may have been left in an
      // unstable / unpredictable state.
      // Except in debug mode, we don't want to show the actual error message to the user as it will almost certainly
      // mean nothing to them
      analysis.display.sanitised = !environment.enableDebug;
      analysis.display.lines = analysis.display.sanitised ? ["We are sorry - an error has occurred."] : [message];
      analysis.sentry.do = actualError.status !== 599;
      analysis.sentry.notifySupportInformed = true;
    }
    // for simplicity, we only apply the overrides once we have determined the required actions
    analysis.display.do = analysis.display.do && !overrides.noDisplay;
    analysis.logout.do = analysis.logout.do && !overrides.noLogout;
    analysis.sentry.do = analysis.sentry.do && !overrides.noSentry && this._options.logErrorsToSentry;
    analysis.reload.do = analysis.reload.do && !overrides.noReload;
    if (!analysis.display.lines) {
      // as we cannot guarantee that our caller will honour analysis.display.do, we should return a value of the expected
      // type here to reduce the chace of further problems...
      analysis.display.lines = [];
    }
    if (analysis.sentry.notifySupportInformed) {
      analysis.display.lines.push(ERR_SUPPORT_INFORMED + ".");
    }
    // We don't want to be telling them that they will be logged out if they haven't actually logged in yet!
    if (analysis.logout.do && analysis.logout.explain && !this.isLoggedIn()) {
      analysis.display.lines.push(ERR_LOGGED_OUT_AND_PRAY);
    }
    if (BETA) {
        analysis.display.lines.push(analysis.actualError.toString());
        analysis.display.lines.push(analysis.errorMessage);
    }
    // as all errors will come through this method, it seems like a good place to do the console log
    if (this._options.logErrorsToConsole) {
      // eslint-disable-next-line no-console
      console.error(analysis.errorMessage);
    }
    return analysis;
  }

  private async handleMessageDisplay(analysis: IErrorAnalysis): Promise<void> {
    if (this.pendingInterractions.messageBox || !analysis.display.do || !(analysis.display.lines?.length > 0)) {
      return;
    }
    this.pendingInterractions.messageBox = true;
    const alert = await this.alertCtrl.create({
      header: "Error!",
      message: analysis.display.lines.map((line) => (line?.startsWith("<p>") ? line : `<p>${line}</p>`)).join(""),
      buttons: [
        {
          text: "OK",
          handler: async () => {
            await alert.dismiss();
            this.pendingInterractions.messageBox = false;
            // now the user has acknowledged the error message, we can ask them to provide further information about
            // the circumstances of that error for the Sentry log (if appropriate)
            if (this.pendingInterractions.sentryEventId) {
              this.handleSentryReportDialog(this.pendingInterractions.sentryEventId);
              delete this.pendingInterractions.sentryEventId;
            }
            if (this.pendingInterractions.reload) {
              this.handleReload(analysis);
              delete this.pendingInterractions.reload;
            }
            return false;
          },
        },
      ],
    });
    return alert.present();
  }

  private handleSentryLogging(analysis: IErrorAnalysis, handled: boolean): void {
    if (!analysis.sentry.do) {
      return;
    }
    const sentryEventId = runOutsideAngular(() =>
      captureException(analysis.actualError, (scope) => {
        scope.addEventProcessor((event) => {
          addExceptionMechanism(event, {
            type: "angular",
            handled,
          });
          return event;
        });
        return scope;
      })
    );
    if (this.pendingInterractions.messageBox) {
      if (!this.pendingInterractions.sentryEventId) {
        this.pendingInterractions.sentryEventId = sentryEventId;
      }
    } else {
      this.handleSentryReportDialog(sentryEventId);
    }
  }

  private handleSentryReportDialog(sentryEventId: string) {
    if (this._options.showSentryReportDialog) {
      // Show a dialog enabling the user to add additional information, describing what they were doing when the error occurred.
      // This information gets attached to the Sentry event.
      Sentry.showReportDialog({ ...this._options.dialogOptions, sentryEventId });
    }
  }

  private handleForcedLogout(analysis: IErrorAnalysis): void {
    if (analysis.logout.do) {
      this.observableEvents.publish("auth:error");
    }
  }

  private handleReload(analysis: IErrorAnalysis): void {
    if (analysis.reload.do) {
      if (this.pendingInterractions.messageBox) {
        // if we're showing a message box, we only want the reload to happen after the user has clicked OK
        this.pendingInterractions.reload = true;
      } else {
        this.observableEvents.publish("auth:reload");
      }
    }
  }

  /**
   * Convert a caught value into the actual error object associated with it (if any), and the most suitable message to use
   * to describe the circumstances of that error.
   */
  public extractError(caught: any): { actualError: any; message: string } {
    const actualError = this.defaultExtractor(caught);
    const message = this.messageFromErr(actualError);
    return { actualError, message };
  }

  /**
   * Attempt to identify the "real" error from errorCandidate.  Handles some special cases - such as HTTP responses -
   * where the actual error is a property of the caught object, rather than being the object itself.
   */
  protected defaultExtractor(errorCandidate: any): any {
    if (!errorCandidate) {
      // create an Error so we get a callstack.  might help a bit in understanding what threw this
      // (presumably undefined or null) value
      return new Error(`${JSON.stringify(errorCandidate)} was thrown`);
    }

    let error = errorCandidate;

    if (typeof error === "string") {
      return error;
    }

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // If it's an error, it can have a rejection property, which gives us the "real" error in the case of an unhandled
    // promise rejection.  Where this exists, it is far more useful than the error itself.
    if (error instanceof Error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (error as any).rejection || error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }
      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }
      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }
      // ...or, in the case where it is a "sanitized" error returned by the handleAPIException method of
      // Plait Backend, it will be an object that is NOT an instance of Error.  Although this means it will
      // have no stack, this is still what we should be using.
      if (error.error) {
        return error.error;
      }
      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // this part came from our own code (though not originally in this function).  it might no longer be relevant - not
    // sure where _body can come from, but we might as well try:
    if (error._body) {
      try {
        return JSON.parse(error._body);
      } catch (e) {
        return error._body;
      }
    }

    // Don't know what it is, so return the original object, and hope this will never happen in reality.
    return errorCandidate;
  }

  private includesAnyOf(str: string, parts: string[]) {
    return parts.some((part) => str.includes(part));
  }

  // this is a modified copy of a function of the same name in Plait.
  private messageFromErr(error: any): string {
    if (!error) {
      return "";
    }
    if (typeof error === "string") {
      return error;
    }
    let result =
      typeof error.data === "string"
        ? error.data
        : error.data?.err || error.data?.message || error.err || error.message || error.statusText;
    if (result?.toString) {
      result = result.toString();
    }
    // if we've not found anything yet, then resort to JSON.stringify, but log these to the console as well, as they're
    // likely to be truncated if this result becomes part of a Sentry log
    if (!result && error.data) {
      result = JSON.stringify(error.data);
      // eslint-disable-next-line no-console
      console.log(result);
    }
    if (!result) {
      if (error.config?.headers?.Authorization) {
        // I think this causes the console.log to be blocked by Sentry, and it's certainly no use to us anyway
        delete error.config.headers.Authorization;
      }
      result = JSON.stringify(error);
      // eslint-disable-next-line no-console
      console.log(result);
    }
    return result;
  }
}
