import { Injectable } from "@angular/core";

import * as constants from "../../constants";

import { BackendProvider, Collection } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { CacheData, DataFromCache, ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { NamedPerson } from "../../models/people/current-client";
import { TagOwner } from "./tag-owner-provider.service";
import { RCErrorHandler } from "../../error.handler";

export interface ICurrentClientType extends NamedPerson, TagOwner {
  tagId?: string;
}

export type ICurrentClientsStore = ICurrentClientType[];

@Injectable({
  providedIn: "root",
})
export class CurrentClientProviderService extends BackendProvider<ICurrentClientType> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<ICurrentClientType>,
    writeCache: WriteCacheService
  ) {
    super(
      backEnd,
      errorHandler,
      observableEvents,
      readCache,
      writeCache,
      CacheKeyPrefix.CurrentClientType,
      Collection.CurrentClient,
      constants.ONE_DAY * 4, // keep data in the cache for a maximum of four days
      constants.ONE_HOUR // but if possible, refresh it after an hour
    );
  }

  public async getCurrentClientDets(id: string): Promise<DataFromCache<NamedPerson>> {
    return this.readCache.get(this.getCacheKey(id));
  }

  public cacheCurrentClients(clients: ICurrentClientsStore): Promise<CacheData<ICurrentClientType>[]> {
    const promises: Promise<CacheData<ICurrentClientType>>[] = [];
    for (const client of clients) {
      promises.push(this.readCache.store(client, this.getCacheKey(client._id)));
    }
    return Promise.all(promises);
  }
}
