import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { PlaitMobileComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage-angular";
import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { RCErrorHandler } from "./error.handler";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { RELEASE } from "./services/release";
import { Offline } from "@sentry/integrations";

Sentry.init(
  {
    dsn: "https://68e36ad480094a02b25e1b13e3fe906c@o117213.ingest.sentry.io/6781775",
    release: RELEASE.split(" ")[0],
    integrations: [new Offline()],   // Now deprecated for https://docs.sentry.io/platforms/javascript/configuration/transports/
  },
  SentryAngular.init
);

@NgModule({
  declarations: [PlaitMobileComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  exports: [],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: RCErrorHandler },
    // { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler({ showDialog: true }), },
  ],
  bootstrap: [PlaitMobileComponent],
})
export class AppModule {}
