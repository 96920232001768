import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "home-scheduling-carer",
    loadChildren: () =>
      import("./pages/home/home-scheduling-carer/home-scheduling-carer.module").then(
        (m) => m.HomeSchedulingCarerPageModule
      ),
  },
  {
    path: "home-non-scheduling",
    loadChildren: () =>
      import("./pages/home/home-non-scheduling/home-non-scheduling.module").then((m) => m.HomeNonSchedulingPageModule),
  },
  {
    path: "home-scheduling-client/:serviceUserId",
    loadChildren: () =>
      import("./pages/home/home-scheduling-client/home-scheduling-client.module").then(
        (m) => m.HomeSchedulingClientPageModule
      ),
  },
  {
    path: "client-meds/:serviceUserId/:ids",
    loadChildren: () =>
      import("./pages/client-sessions/client-meds/client-meds.module").then((m) => m.ClientMedsPageModule),
  },
  {
    path: "client-meds/:serviceUserId",
    loadChildren: () =>
      import("./pages/client-sessions/client-meds/client-meds.module").then((m) => m.ClientMedsPageModule),
  },
  {
    path: "client-tasks/:serviceUserId/:ids",
    loadChildren: () =>
      import("./pages/client-sessions/client-tasks/client-tasks.module").then((m) => m.ClientTasksPageModule),
  },
  {
    path: "client-tasks/:serviceUserId",
    loadChildren: () =>
      import("./pages/client-sessions/client-tasks/client-tasks.module").then((m) => m.ClientTasksPageModule),
  },
  {
    path: "client-careplans/:serviceUserId/:effectivedt",
    loadChildren: () =>
      import("./pages/client-careplans/client-careplans.module").then((m) => m.ClientCareplansPageModule),
  },
  {
    path: "client-meds-summary/:ids",
    loadChildren: () =>
      import("./pages/event-data/client-login/client-meds-summary/client-meds-summary.module").then(
        (m) => m.ClientMedsSummaryPageModule
      ),
  },
  {
    path: "client-tasks-summary/:ids",
    loadChildren: () =>
      import("./pages/event-data/client-login/client-tasks-summary/client-tasks-summary.module").then(
        (m) => m.ClientTasksSummaryPageModule
      ),
  },
  {
    path: "client-event-logs/:ids",
    loadChildren: () =>
      import("./pages/event-data/client-login/client-event-logs/client-event-logs.module").then(
        (m) => m.ClientEventLogsModule
      ),
  },
  {
    path: "event-logs/:ids",
    loadChildren: () =>
      import("./pages/event-data/carer-login/event-logs/event-logs.module").then((m) => m.EventLogsPageModule),
  },
  {
    path: "find-client",
    loadChildren: () => import("./pages/find-client/find-client.module").then((m) => m.FindClientPageModule),
  },
  {
    path: "select-client",
    loadChildren: () => import("./pages/select-client/select-client.module").then((m) => m.SelectClientPageModule),
  },
  {
    path: "assign-phone",
    loadChildren: () => import("./pages/assign-phone/assign-phone.module").then((m) => m.AssignPhonePageModule),
  },
  {
    path: "request-bookings-list",
    loadChildren: () =>
      import("./pages/request-bookings-list/request-bookings-list.module").then((m) => m.RequestBookingsListPageModule),
  },
  {
    path: "event-details/:uniqueEventId",
    loadChildren: () => import("./pages/event-details/event-details.module").then((m) => m.EventDetailsPageModule),
  },
  {
    path: "event-details/:uniqueEventId/:method/:boundary",
    loadChildren: () => import("./pages/event-details/event-details.module").then((m) => m.EventDetailsPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
