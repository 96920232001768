import { Injectable } from "@angular/core";

import { Collection, IIdentified } from "../../lib/backendProvider";
import { CacheKeyPrefix } from "../../lib/providerWithCaching";
import { ReadCacheService } from "../../services/readCacheService";
import { WriteCacheService } from "../../services/writeCacheService";
import { ObservableEventsService } from "../../services/observableEventsService";
import { BackendService } from "../../services/backend.service";
import { ITimestamped, TimestampedImmutableBackendProvider } from "../../lib/immutableBackendProvider";
import { RCErrorHandler } from "../../error.handler";

// this is a cut-down version of plait.IRunObj containing only those properties that we require
export interface IRun extends IIdentified, ITimestamped {
  id: string;
  longName?: string;
  shortName?: string;
  numCarers: number;
}

@Injectable({
  providedIn: "root",
})
export class RunProviderService extends TimestampedImmutableBackendProvider<IRun> {
  constructor(
    backEnd: BackendService,
    errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<IRun>,
    writeCache: WriteCacheService
  ) {
    super(backEnd, errorHandler, observableEvents, readCache, writeCache, CacheKeyPrefix.Run, Collection.Run);
  }
}
