<ion-header>
  <ion-toolbar>
    <ion-title>
      Unauthenticated Login
    </ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon [className]="!IOS ? 'show-div' : 'hide-div'" name="arrow-back-circle"></ion-icon>
        <ion-text [className]="IOS ? 'show-div' : 'hide-div'" color="primary">Back</ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-list>
    <div [className]="step == 0 ? 'show-div' : 'hide-div'">
      <ion-item lines="none">
        This is a feature to allow health professionals, such as ambulance staff, to gain access to recent visits data,
        so they can find out how the patient appeared at the last visit, and what meds were taken / refused.  If you are
        not such a medical professional you must not proceed.
      </ion-item>
      <ion-item>
        <ion-input id="email-input" #emailInput type="email" [(ngModel)]="email" autocomplete="email" autofocus (ionChange)="checkEmail()" placeholder="Enter your email"></ion-input>
      </ion-item>
      <ion-item id="email-btn" button="true" color="primary" (click)="logEmail()" [disabled]="buttonDisabled">
        OK
      </ion-item>
    </div>
    <div [className]="step == 1 ? 'show-div' : 'hide-div'">
      <ion-item lines="none">
        We have sent you an email containing a six digit PIN.  It may take a minute or two to arrive.  When you get it please enter the PIN below.
      </ion-item>
      <ion-item>
        <ion-input id="pin-input" #pinInput inputmode="numeric" [(ngModel)]="pin" (ionChange)="checkPIN()" placeholder="Enter the PIN"></ion-input><br />
        <div [className]="showPINError ? 'show-div' : 'hide-div'">
          <p style="color: red">Incorrect PIN - please try again</p>
        </div>
      </ion-item>
    </div>
  </ion-list>
</ion-content>


