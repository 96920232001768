import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LogLvl, Logger } from "../lib/logger";

type Dialog = HTMLIonAlertElement | HTMLIonModalElement;

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private dialogs: Dialog[] = [];
  private logger: Logger;

  constructor() {
    this.logger = new Logger();
  }

  public addOne(dialog: Dialog): void {
    if (dialog) {
      this.dialogs.push(dialog);
    }
  }

  public removeOne(dialog: any): void {
    const index = this.dialogs.findIndex((d) => d === dialog);
    if (index !== -1) {
      this.dialogs.splice(index, 1);
    }
  }

  public async closeAll(): Promise<void> {
    this.logger.log(`DialogService.closeAll called (${this.dialogs.length} dialog(s))`, LogLvl.Heavy);
    for (const dialog of this.dialogs) {
      if (dialog?.dismiss) {
        await dialog.dismiss();
      }
    }
    this.dialogs = [];
  }

  public async show(dialog: Dialog): Promise<any> {
    this.logger.log(`DialogService.show called`, LogLvl.Heavy);
    this.addOne(dialog);
    await dialog.present();
    this.logger.log(`dialog presented (outerText: "${dialog.outerText?.replace(/\n/g, "")}")`, LogLvl.Heavy);
    const result = await dialog.onDidDismiss();
    this.logger.log(`dialog dismissed (result: ${JSON.stringify(result)})`, LogLvl.Heavy);
    this.removeOne(dialog);
    return result;
  }

  public async closeModal(modalCtrl: ModalController, data?: any): Promise<boolean> {
    this.logger.log(`DialogService.closeModal called (source: ${(modalCtrl as any)?.injector?.source})`, LogLvl.Heavy);
    let result: boolean;
    // checking this.modalCtrl?.getTop() and using { dismissed: true } as the data when none is provided are a speculative fix
    // to "overlay does not exist", seen regularly in Sentry.  E.g., https://reallycare.sentry.io/issues/4340864796/events/7252d758800b4b5a9ebc88c3b97b080c/
    if (modalCtrl?.getTop) {
      const overlay = await modalCtrl.getTop();
      if (overlay) {
        const outerText = overlay.outerText?.replace(/\n/g, "");
        this.logger.log(
          `attempting modalCtrl.dismiss (canDismiss: ${overlay.canDismiss}; outerText "${outerText}")...`,
          LogLvl.Heavy
        );
        // don't check !data here, because it's possible (e.g, on an optional capture comments page)
        // that data could be "", which is perfectly valid
        if (data === null || data === undefined) {
          data = { dismissed: true };
        }
        result = await modalCtrl.dismiss(data);
        this.logger.log(`modalCtrl.dismiss successful.`, LogLvl.Heavy);
      } else {
        this.logger.log(`modalCtrl.getTop() returned nothing.`, LogLvl.Heavy);
      }
    } else {
      this.logger.log(`modalCtrl.getTop is not assigned.`, LogLvl.Heavy);
    }
    return result;
  }
}
