import { Inject, Optional } from "@angular/core";

import * as stringify from "json-stable-stringify";

import { BackendService } from "../services/backend.service";
import { CacheKeyPrefix, ProviderWithCaching } from "./providerWithCaching";
import { ObservableEventsService } from "../services/observableEventsService";
import { WriteCacheService } from "../services/writeCacheService";
import { environment, DOMAIN } from "../../environments/environment";
import { LogLvl } from "./logger";
import { MobileError, RCErrorHandler } from "../error.handler";
import {
  DataFromCache,
  defaultCacheAgeLimit,
  defaultCacheRefreshAfter,
  ReadCacheService,
} from "../services/readCacheService";

export interface IIdentified {
  _id: string;
}

export enum DataSource {
  InMemory,
  Cache,
  Backend,
  None,
}

export enum URLType {
  Custom = "",
  Standard = "/api",
  Extended = "/apix",
}

export enum Collection {
  Event = "event",
  Person = "person",
  EventType = "eventType",
  Run = "run",
  CurrentClient = "person",
  Medication = "medication",
  Medicine = "medicine",
  CareplanStructure = "careplanStructure",
  Careplan = "careplan",
  None = "",
  Test = "whatever",
}

interface IGetListParams<T> {
  skipAuth?: boolean;
  inMemory?: { [id: string]: DataFromCache<T> };
}

/**
 * An extension of CacheProviderService for data types that are retrieved from (and potentially also updated to) an http backend.
 * Once retrieved, data will be cached to reduce network traffic and support offline working.
 */
export class BackendProvider<T> extends ProviderWithCaching<T> {
  constructor(
    protected backEnd: BackendService,
    protected errorHandler: RCErrorHandler,
    observableEvents: ObservableEventsService,
    readCache: ReadCacheService<T>,
    protected writeCache: WriteCacheService,
    cacheKeyPrefix: CacheKeyPrefix,
    protected collection: Collection,
    @Optional() @Inject("cacheAgeLimit") cacheAgeLimit: number = null,
    @Optional() @Inject("cacheRefreshAfter") cacheRefreshAfter: number = null
  ) {
    super(readCache, observableEvents, cacheKeyPrefix);
    this.cacheAgeLimit = cacheAgeLimit || defaultCacheAgeLimit;
    this.cacheRefreshAfter = cacheRefreshAfter || defaultCacheRefreshAfter;
  }

  public async *getChangeableId(
    id: string,
    urlType: URLType,
    urlPattern = "%%",
    params: {
      skipAuth?: boolean;
      forceCheckBackend?: boolean;
      preCallback?: (incomingData: T, cachedData?: T) => Promise<any>;
      postCallbacks?: ((incomingData: T) => Promise<void>)[];
    } = {}
  ): AsyncIterableIterator<{ data: T; source: DataSource }> {
    function handlePostCallbacks(obj: T, objSource: "cached" | "backend") {
      // Any other non-blocking code needing to be executed only after new data is
      // retrieved from the backend can be provided through these callbacks.  They assume
      // no changes will be made to obj, and thus no requirement to update the cache upon completion.
      if (params.postCallbacks) {
        for (const callback of params.postCallbacks) {
          const callbackName = `postCallback (${callback.name.replace("bound ", "")} on ${objSource} data)`;
          this.logger.log(`Calling postNonDecoratingCallback: ${callbackName}`, LogLvl.Heavy);
          callback(obj)
            .then(() => {
              this.logger.log(`${callback.name} returned.`, LogLvl.Light);
            })
            .catch((e) => {
              const analysis = this.errorHandler.extractError(e);
              const adjustedMsg = `Error during non-decorating callback (${callbackName}): ${analysis.message}`;
              let error = analysis.actualError;
              if (error instanceof MobileError) {
                error.baseMsg = adjustedMsg;
              } else {
                // don't do error.message = adjustedMsg; because this can result in "Attempted to assign to readonly property."
                // (even for something that is not an instance of MobileError).  This is safer, and I don't think we're actually
                // going to lose any useful information doing this:
                error = new Error(adjustedMsg);
              }
              throw error;
            });
        }
      }
    }

    this.logger.log(
      `getChangeableId(): collection=${this.collection}; id=${id}; urlType=${urlType}; urlPattern=${urlPattern}; skipAuth=${params.skipAuth}.`,
      LogLvl.Heavy
    );
    const cacheKey = this.getCacheKey(id);
    const cached = await this.getCached(cacheKey);
    let getFromServer = true;
    if (cached) {
      // Send the cached version to be getting on with...
      this.logger.log(`Yielding the cached ${this.collection} data...`, LogLvl.Heavy);
      yield { data: cached.obj, source: DataSource.Cache };
      handlePostCallbacks.call(this, cached.obj, "cached");
      getFromServer = cached.needsRefresh;
    }    
    if (this.collection === Collection.None) {
      // Some of the immutable data stores don't have a collection and consequently don't have a way for us to request
      // data from the backend if it does not exist in the cache.
      if (!cached) {
        // This should never happen, as the theory is that the data will always be cached.  However, we should inform
        // our caller if this does ever happen with the following yield.
        yield { data: undefined, source: DataSource.None };
      }
      // Whether the data was found in the cache or not, we have to return now, as the server won't know how to provide
      // us with data.
      return;
    }
    if (!environment.production && environment.enableDebug && this.backEnd.immediateCacheRefresh) {
      params.forceCheckBackend = true;
    }
    if (getFromServer || params.forceCheckBackend) {
      const NO_NETWORK = "Are we offline, or the server unreachable?";
      const NO_YIELD = "Returning without yield.";
      this.logger.log(`No cached ${this.collection} data, or cache expired.  Going to backend...`, LogLvl.Heavy);
      let obj = await this.getFromBackEnd(urlType, urlPattern.replace("%%", id), { skipAuth: params.skipAuth }).catch(
        (err) => {
          this.logger.log(`Error from backend: ${JSON.stringify(err)}`, LogLvl.Error);
          // we have to throw this error because there are many callers of this function, with varying requirements
          // as far as error handling goes.  each caller needs to decide for itself how to handle
          // errors, remembering that "offline" errors (status 0, 200 and 504) are swallowed because
          // performHttpGet() doesn't pass a value to the noNetworkAction parameter of handleHttpError()
          throw err;
        }
      );
      if (!obj) {
        this.logger.log(`No ${this.collection} retrieved from backend. ${NO_NETWORK} ${NO_YIELD}`, LogLvl.Error);
        return;
      }
      this.logger.log(
        `${this.collection} data retrieved from backend: ${this.formatDataForLogging(obj)}`,
        LogLvl.Light
      );
      // This callback can be used to make blocking modifications to the data that is
      // returned from the backend before it is cached and before it is yielded
      if (params.preCallback) {
        const callbackName = params.preCallback.name.replace("bound ", "");
        this.logger.log(`Calling preCacheBlockingCallback: ${callbackName}`, LogLvl.Heavy);
        obj = await params.preCallback(obj, cached?.obj);
        if (!obj) {
          this.logger.log(`${callbackName} returned nothing. ${NO_NETWORK} ${NO_YIELD}`, LogLvl.Error);
          return;
        }
      }
      // Do this whether or not the data has changed, as doing so will refresh the expiry.
      this.logger.log(`Caching ${this.collection} data retrieved from backend.  Key: ${cacheKey}.`, LogLvl.Light);
      await this.store(obj, id);
      this.logger.log(`Caching completed successfully.`, LogLvl.Light);
      // Only yield after the data has been stored.  Otherwise, our caller might do something that assumes
      // the data it has been yielded is already in the cache (as event-details does, for example).
      // We perform the comparison between stringified objects rather than using (for example) deep-equal, as this is
      // MUCH faster.  It also makes trouble-shooting easier, as you can save the two strings as text files and then
      // compare these using a tool such as Meld.  This provides a reasonably easy means of understand what has changed
      // when changes were not expected.
      // Note that we're using the json-stable-stringify library here, rather than JSON.stringify, because this
      // ensures that the order of the properties in the objects being stringified does not affect the generated string.
      if (cached?.obj && stringify(cached.obj) === stringify(obj)) {
        this.logger.log("Backend data has not changed.  Not yielding again.", LogLvl.Heavy);
      } else {
        this.logger.log("Backend data has changed (or was not cached to begin with).  Yielding...", LogLvl.Heavy);
        this.logger.log(`Collection=${this.collection}; id=${id}`, LogLvl.Heavy);
        this.logger.log(`Value: ${JSON.stringify(obj, null, 2)}`, LogLvl.Heavy);
        yield { data: obj, source: DataSource.Backend };
      }
      handlePostCallbacks.call(this, obj, "backend");
    } else {
      this.logger.log("Fresh cached data was found.  No need to go to backend.", LogLvl.Heavy);
    }
    this.logger.log(`getChangeableId(${this.collection}) end.`, LogLvl.Heavy);
  }

  protected async *getChangeableList(
    idList: string[],
    urlType: URLType,
    urlPattern = "%%",
    params: IGetListParams<T>
  ): AsyncIterableIterator<T[]> {
    this.logger.log(
      `getChangeableList(): collection=${this.collection}; idList=${idList}; urlType=${urlType}; urlPattern=${urlPattern}; skipAuth=${params.skipAuth}.`,
      LogLvl.Heavy
    );
    let resultSent = false;
    const cachedItems: Array<DataFromCache<T>> = [];
    for (const id of idList) {
      // if we've been provided with an in-memory list of DataFromCache<T> items, we'll prioritise
      // retrieval from there on the basis that reading from cache storage is relatively expensive.  for single
      // item lookups in isolation, that cost is insignificant, but when we're dealing with a list - and needing to
      // make an individual lookup for each item - it quickly adds up.
      const inMemory = params.inMemory?.[id];
      if (inMemory) {
        cachedItems.push(inMemory);
      } else {
        const cached = await this.getCached(this.getCacheKey(id));
        cachedItems.push(cached);
        // add the cached item to params.inMemory so we'll be able to retrieve it faster in the future
        if (cached && params.inMemory) {
          params.inMemory[id] = cached;
        }
      }
    }
    // Check that all values are found in cache - if they are, return the cached results immediately, for our caller
    // to be getting on with.  If not, we need to wait for a backend read.
    if (!cachedItems.some((c) => !c)) {
      resultSent = true;
      this.logger.log(`All ${this.collection} items founds in the cache.  Yielding the cached data...`, LogLvl.Heavy);
      yield cachedItems.map((c) => c.obj);
    }
    const needLookup = cachedItems.reduce((acc, curValue, idx) => {
      if (!curValue || curValue.needsRefresh) {
        acc[idx] = idList[idx];
      }
      return acc;
    }, []);
    if (needLookup.length > 0) {
      this.logger.log(
        `All ${this.collection} items were not in the cache (or had expired).  Going to backend...`,
        LogLvl.Heavy
      );
      const keys = needLookup.filter((n) => !!n);
      const fromServer: any[] = await this.getFromBackEnd(urlType, urlPattern.replace("%%", keys.join('","')), {
        skipAuth: params.skipAuth,
      }).catch((err) => {
        this.logger.log(`Error from backend2: ${JSON.stringify(err)}`, LogLvl.Error);
        throw err;
      });
      if (fromServer) {
        this.logger.log(
          `${fromServer.length} ${this.collection} record${fromServer.length === 1 ? "" : "s"} retrieved from backend`,
          LogLvl.Light
        );
      } else {
        this.logger.log(`No ${this.collection} data received from backend.  Offline?  Returning...`, LogLvl.Light);
        return;
      }
      for (let i = 0; i < keys.length; i++) {
        const cacheKey = this.getCacheKey(keys[i]);
        const formattedData = this.formatDataForLogging(fromServer[i]);
        this.logger.log(
          `Processing ${this.collection} item ${i}: key: ${cacheKey}; data: ${formattedData}`,
          LogLvl.Heavy
        );
        // We will put the looked up item in the cachedItems array (ie the results) making
        // use of the fact that the order of idList and cachedItems is the same
        const idx = idList.indexOf(keys[i]);
        // If we haven't uncovered a change yet, then check for one
        if (resultSent && (!cachedItems[idx] || stringify(fromServer[i]) !== stringify(cachedItems[idx]))) {
          resultSent = false;
        }
        // Do this even if the data is unchanged, as doing so will refresh the expiry of the cache record
        const cachedItem = await this.store(fromServer[i], keys[i]);
        cachedItems[idx] = cachedItem;
        if (params.inMemory) {
          params.inMemory[keys[i]] = cachedItem;
        }
      }
      if (resultSent) {
        this.logger.log(`Unchanged ${this.collection} data - not returning anything more.`, LogLvl.Heavy);
      } else {
        this.logger.log(
          `At least one of the ${this.collection} items was not cached, or was stale.  Yielding back-end data...`,
          LogLvl.Heavy
        );
        yield cachedItems.map((c) => c.obj);
      }
    }
    this.logger.log(`getChangeableList(${this.collection}) end.`, LogLvl.Heavy);
  }

  /**
   * Return data that is not expected to change.  Rather than returning the cached
   * data and then retrieving updated data from the backend, we just return the
   * data from one source (the cache if it's cached, else the backend)
   */
  protected async getImmutable(
    id: string,
    urlType: URLType,
    urlPattern = "%%",
    skipAuth = false
  ): Promise<{ data: T; source: DataSource }> {
    this.logger.log(
      `getImmutable(): collection=${this.collection}; id=${id}; urlType=${urlType}; urlPattern=${urlPattern}; skipAuth=${skipAuth}`,
      LogLvl.Heavy
    );
    const iterator = this.getChangeableId(id, urlType, urlPattern, { skipAuth });
    // Generally, super.getChangeableId() can yield two values: firstly from the cache,
    // and then, if the cached data has expired, from the backend.
    // Knowing we only need the first of these two values, we can provide a simplified
    // API by returning just the first value from the iterator.
    const result = (await iterator.next()).value;
    this.logger.log(`getImmutable(${this.collection}) end.`, LogLvl.Heavy);
    return result;
  }

  /**
   * A variant of getImmutable used to retrieve a list of records rather than a single
   * record.
   */
  protected async getImmutableList(
    idList: string[],
    urlType: URLType,
    urlPattern = "%%",
    params: IGetListParams<T>
  ): Promise<T[]> {
    this.logger.log(
      `getImmutableList(): collection=${this.collection}; idList=${idList}; urlType=${urlType}; urlPattern=${urlPattern}; skipAuth=${params.skipAuth}`,
      LogLvl.Heavy
    );
    const iterator = this.getChangeableList(idList, urlType, urlPattern, params);
    const result = (await iterator.next()).value;
    this.logger.log(`getImmutableList(${this.collection}) end.`, LogLvl.Heavy);
    return result;
  }

  /**
   * A variant of getImmutable that can be used where the record with the given
   * id is known to be already cached.
   */
  protected async getCachedImmutable(id: string, throwOnCacheMiss = true): Promise<T> {
    return this.getFromId(id, throwOnCacheMiss);
  }

  private getURLPrefix(urlType: URLType, overrideCollection?: string): string {
    return `${urlType}/${overrideCollection || this.collection}`;
  }

  // Retrieve something from the backend.  This might be because
  // the cache needs refreshing, or we're retrieving something that doesn't get cached
  // for whatever reason.
  // NB: The type of data retrieved by this call might not necessarily be T.
  public getFromBackEnd(
    urlType: URLType,
    url: string,
    params?: { skipAuth?: boolean; collection?: string }
  ): Promise<any> {
    let urlPrefix = this.getURLPrefix(urlType, params?.collection);
    if (!urlPrefix.endsWith("/") && !url.startsWith("?")) {
      urlPrefix += "/";
    }
    const fullUrl = `${DOMAIN}${urlPrefix}${url}`;
    return this.backEnd.performHttpGet(fullUrl, params?.skipAuth);
  }

  // Add an entry to the write cache for the purpose of updating the backend
  // document with the given id with the data provided, then nudge the backend
  // service to see whether this update can be sent to the server now.
  // NB: Callers to this method are responsible for updating the relevant read
  // cache so these stay in sync with what we hope will be written back to the server
  // in the near future...
  protected async cacheABackendWrite(
    urlType: URLType,
    id: string,
    data: any,
    params?: { collection?: string; replaceExistingItem?: boolean }
  ): Promise<void> {
    const url = `${this.getURLPrefix(urlType, params?.collection)}/${id}`;
    this.logger.log(`cacheABackendWrite(): collection = ${this.collection}; url = ${url}.`, LogLvl.Light);
    await this.writeCache.cacheAnUpdate(url, data, params?.replaceExistingItem);
    this.logger.log("nudging from base-provider", LogLvl.Heavy);
    this.observableEvents.publish("backend:nudge", url);
  }
}
